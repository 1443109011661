import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { DEFAULT_LANGUAGE, LANGUAGES } from 'src/constants'

export const SideLngsWrapper = () => {
  const { sideLng, simpleProductId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    // Check if `sideLng` is a valid language code
    if (LANGUAGES.includes(sideLng)) {
      return
    }

    if (sideLng === DEFAULT_LANGUAGE && simpleProductId) {
      // Ensure `en` is in the path before trying to replace it
      if (location.pathname.includes(DEFAULT_LANGUAGE)) {
        const newPath = location.pathname.replace('/en', '')
        navigate(newPath, { replace: true })
      }
      return
    }
    window.location.replace('https://trackgood.io/')
  }, [sideLng, simpleProductId, location, navigate])

  return LANGUAGES.includes(sideLng) ? <Outlet /> : null
}
