import { Dispatch, SetStateAction } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { IImpact } from 'src/types'
import { HoverWrapper } from 'src/components'

import { StepImpactsList } from './journey-step-card.styled'
import { AvatarImg } from '../step-info/step-company.styled'
import { useImpacts } from 'src/hooks'

interface IProps {
  impacts: IImpact[]
  setShowDescr: Dispatch<SetStateAction<boolean>>
  isMobile: boolean
}

export const ImpactsList = ({ impacts }: IProps) => {
  const location = useLocation()
  const navigate = useNavigate()

  const { isLoading: isImpactsLoading, isImpacts } = useImpacts()

  const filteredImpacts = impacts.filter((impact) => impact.journey_page_show)

  const uniqueImpacts = {}

  filteredImpacts.forEach((obj) => {
    if (!uniqueImpacts[obj.id]) {
      uniqueImpacts[obj.id] = obj
    }
  })

  const impactsToShow = Object.values(uniqueImpacts) as IImpact[]

  const isImpactPage = !isImpactsLoading && isImpacts

  const handleImpactClick = (id: string) => {
    if (!isImpactPage) {
      return
    }

    const currentPath = location.pathname
    const newPath = currentPath.replace('journey', 'impacts')

    navigate(`${newPath}#${id}`)
  }

  return (
    <StepImpactsList>
      {impactsToShow.map((item) => (
        <li
          key={item.id}
          style={{ cursor: isImpactPage ? 'pointer' : 'default' }}
          onClick={() => handleImpactClick(item.id)}
        >
          <HoverWrapper title={item.name}>
            <AvatarImg
              borderRadius={'6px'}
              // width={'40px'}
              height={'40px'}
              src={item.image_small || item.image}
              alt=""
            />
            {/* <div
              style={{
                backgroundImage: `url(${item.image_small || item.image})`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                borderRadius: '6px',
                height: '40px',
                width: '40px',
                backgroundRepeat: 'no-repeat'
              }}
            /> */}
          </HoverWrapper>
        </li>
      ))}
      {/* {isMobile && (
        <li>
          <IconButton
            onClick={() => {
              setShowDescr((prev) => !prev)
            }}
          >
            <MoreHoriz />
          </IconButton>
        </li>
      )} */}
    </StepImpactsList>
  )
}
