import { useNavigate, useParams } from 'react-router-dom'
import { Loader } from 'src/components'
import { usePGetProductSimpleId } from 'src/hooks'

interface IProps {
  additionalPath?: string
}

export function RedirectUrlPage({ additionalPath }: IProps) {
  const { productId } = useParams()
  const navigate = useNavigate()

  const { data, isLoading, error } = usePGetProductSimpleId(productId)

  if (isLoading) {
    return <Loader />
  }

  if (error || !data) {
    window.location.replace('https://trackgood.io/')
    return
  }

  if (additionalPath) {
    navigate(`/${data}/${additionalPath}`)
    return null
  }

  navigate(`/${data}`)

  return null
}
