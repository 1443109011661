import { IAccountImpact } from 'src/types'
import ArrowIcon from 'src/assets/icons/arrow.icon'
import ArrowIconLeft from 'src/assets/icons/arrow.icon-left'
import { useRef } from 'react'
import { useIsTablet } from 'src/hooks'
import { AccountImpactsList } from './account-impacts-list'
import { AccountImpactsWrapper, StyledScrollBtn } from './account-impacts.styled'

interface IProps {
  impacts: IAccountImpact[]
}

export const AccountImpacts = ({ impacts }: IProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const isTablet = useIsTablet()
  const scrollLeft = () => {
    if (scrollContainerRef) {
      scrollContainerRef.current.scrollLeft += -130
    }
  }
  const scrollRight = () => {
    if (scrollContainerRef) {
      scrollContainerRef.current.scrollLeft += 130
    }
  }

  return (
    <AccountImpactsWrapper>
      <StyledScrollBtn
        onClick={scrollLeft}
        style={{ display: isTablet || impacts.length < 6 ? 'none' : '' }}
      >
        <ArrowIconLeft />
      </StyledScrollBtn>
      {impacts.length > 0 && (
        <AccountImpactsList
          items={impacts}
          ref={scrollContainerRef}
          withArrows={!(isTablet || impacts.length < 6)}
        />
      )}
      <StyledScrollBtn
        onClick={scrollRight}
        style={{ display: isTablet || impacts.length < 6 ? 'none' : '' }}
      >
        <ArrowIcon />
      </StyledScrollBtn>
    </AccountImpactsWrapper>
  )
}
