import { useEffect, useRef, useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import { Button } from '@mui/material'
import { NavigateBefore, NavigateNext, FiberManualRecord } from '@mui/icons-material'

import { IMedia } from 'src/types'

import {
  ImageSt,
  VideoSt,
  mediaCarousel,
  navButtonWrapper,
  navIcon,
  indicatorWrapper,
} from './product-image.styled'

interface IProps {
  media: Array<IMedia>
}

export const MediaCarousel = ({ media }: IProps) => {
  const [autoplay, setAutoplay] = useState(true)
  const videoRef = useRef<HTMLVideoElement | null>(null)

  const handleDotClick = () => {
    setAutoplay(false)
  }

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.setAttribute('muted', `true`)
    }
    if (videoRef.current && videoRef.current.paused) {
      videoRef.current.play().catch((error) => {
        console.error('Autoplay failed:', error)
      })
    }
  }, [])

  return (
    <>
      <Carousel
        stopAutoPlayOnHover
        animation="slide"
        autoPlay={autoplay}
        height={'54lvh'}
        indicators={media.length > 1}
        indicatorContainerProps={{
          className: indicatorWrapper,
        }}
        navButtonsAlwaysVisible={false}
        navButtonsAlwaysInvisible={media.length == 1}
        duration={800}
        interval={8000}
        className={mediaCarousel}
        navButtonsWrapperProps={{
          className: navButtonWrapper,
        }}
        NavButton={({ onClick, className, style, next, prev }) => {
          const handleClick = () => {
            onClick()
            setAutoplay(false)
          }
          return (
            <div
              style={{
                width: '60px',
                marginInline: '-10px',
                cursor: 'pointer',
                height: '55vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={handleClick}
            >
              <Button
                className={className}
                sx={{
                  width: 40,
                  height: 40,
                  minWidth: 0,
                  margin: '0px',
                  backgroundColor: 'black',
                  opacity: 0,
                  transition: 'all 0.3s ease-in-out',
                }}
                onClick={handleClick}
                style={style}
              >
                {next && <NavigateNext className={navIcon} />}
                {prev && <NavigateBefore className={navIcon} />}
              </Button>
            </div>
          )
        }}
        IndicatorIcon={
          <FiberManualRecord sx={{ width: '15px', height: '15px' }} onClick={handleDotClick} />
        }
      >
        {media.map((item) => {
          if (item.type.startsWith('video')) {
            return (
              <VideoSt
                ref={videoRef}
                key={item.id}
                src={item.link}
                loop
                muted
                autoPlay
                playsInline
              />
            )
          }

          return <ImageSt loading="eager" key={item.id} alt={item.title} src={item.link} />
        })}
      </Carousel>
    </>
  )
}
