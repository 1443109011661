import { useLocation } from 'react-router-dom'

import { BlockchainVerifiedBanner } from '../blockchain-verified-banner'
import { IUnSdg, IScan } from 'src/types'
import {
  Item,
  ItemBottom,
  ItemImage,
  ItemText,
  LinkSt,
  ItemTextWrapper,
} from './impact-cards-hor.styled'
import { Box } from '@mui/material'
interface IProps {
  title: string | number
  subtitle: string
  image: string
  unSdg: Array<IUnSdg>
  id: string
  quantityName: string
  scans: IScan[]
  primaryColor: string
  walletAddress: string
  showWalletAddress: boolean
  showBcInfo: boolean
  accountImage: string
  isImpactsPage: boolean
}

export const ImpactCard = ({
  image,
  title,
  subtitle,
  id,
  quantityName,
  scans,
  primaryColor,
  walletAddress,
  showWalletAddress,
  showBcInfo,
  accountImage,
  isImpactsPage,
}: IProps) => {
  const location = useLocation()

  return (
    <Box sx={{ position: 'relative' }}>
      <Item disabled={!isImpactsPage}>
        <LinkSt
          style={{ pointerEvents: isImpactsPage ? 'auto' : 'none' }}
          to={`${location.pathname}/impacts#${id}`}
        >
          <ItemImage style={{ backgroundImage: `url(${image})` }} />
          <ItemBottom isQuantity={!!title}>
            {title && (
              <ItemTextWrapper>
                <ItemText variant="h4">{title}</ItemText>
                <ItemText variant="h6">{quantityName}</ItemText>
              </ItemTextWrapper>
            )}
            <ItemText
              // sx={{ fontSize: '10px' }}
              variant="body2"
            >
              {subtitle}
            </ItemText>
          </ItemBottom>
        </LinkSt>
      </Item>
      {showBcInfo && scans && scans.length > 0 && (
        <BlockchainVerifiedBanner
          size={40}
          scans={scans}
          stroke={primaryColor}
          walletAddress={walletAddress}
          showWalletAddress={showWalletAddress}
          impactImage={image}
          impactName={subtitle}
          accountImage={accountImage}
        />
      )}
    </Box>
  )
}
