import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

import { TitleWrapper } from './journey-detailed.styled'

// interface IProps {
//   journeyName: string
// }

export const Title = () => {
  const [t] = useTranslation()

  return (
    <TitleWrapper>
      <Typography variant="h1">{t('journey-header-title')}</Typography>
      {/* <TwitterShare title={journeyName} /> */}
    </TitleWrapper>
  )
}
