import { styled } from '@mui/material'
import { css } from '@emotion/css'

export const ImageSt = styled('img')`
  width: 100%;
  object-fit: cover;
`

export const VideoSt = styled('video')`
  width: 100%;
  object-fit: cover;
  display: block;
`

export const navIcon = css`
  color: white;
  transition: all 0.3s ease-in-out;
`
