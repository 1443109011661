import { styled } from '@mui/material'

export const List = styled('div')<{ withArrows?: boolean }>(
  ({ theme, withArrows }) => `
  padding: 15px ${!withArrows ? '30px' : '0'} ${theme.spacing(1.25)};
  width: 85vw;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 0.5em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  display: flex;
  justify-content: center;

  gap: ${theme.spacing(2.5)};
`,
)

export const AccountImpactsWrapper = styled('div')`
  display: flex;
  gap: 25px;
  justify-content: center;
  align-items: center;
`
export const StyledScrollBtn = styled('div')`
  cursor: pointer;
`
