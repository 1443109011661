import { css } from '@emotion/css'
import { styled } from '@mui/material'
import { COLORS } from 'src/styles/common'

export const FooterSt = styled('footer')<{ primaryColor?: string }>`
  margin-top: ${({ theme }) => theme.spacing(6.25)};
  /* width: 98%; */
  display: flex;
  /* padding: 0 16px; */
  justify-content: flex-end;
  background-color: ${(props) => props?.primaryColor || COLORS.PRIMARY};
  padding: 5px;
  margin-top: auto;
`

export const ContentSt = styled('div')`
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`

export const versionSt = css`
  display: inline-flex;
  margin-left: auto;
`
