import { styled } from '@mui/material'

export const TitleWrapper = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(2.5)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const InfoWrapper = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(3.5)};
`

export const DescriptionWrapper = styled('div')(() => `margin-bottom: 20px;`)
