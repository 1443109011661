import { useTheme } from '@mui/material'

import { IAccount } from 'src/types'

import { LinkButton } from './link-button.component'
import { LinksList } from './account-links.styled'
import { FacebookIcon } from 'src/assets/icons/v2/FacebookIcon'
import { InstagramIcon } from 'src/assets/icons/v2/InstagramIcon'
import { TwitterIcon } from 'src/assets/icons/v2/TwitterIcon'
import { LinkedinIcon } from 'src/assets/icons/v2/LinkedinIcon'
import { WorldIcon } from 'src/assets/icons/v2/WorldIcon'
import { EmailIcon } from 'src/assets/icons/v2/EmailIcon'

type IProps = {
  socials: IAccount['socials']
  email?: string
  website?: string
}

export const AccountLinks = ({ email, website, socials }: IProps) => {
  const theme = useTheme()
  const { facebook, instagram, x, linkedin } = socials
  return (
    <LinksList>
      {facebook && (
        <LinkButton href={facebook}>
          <FacebookIcon size={35} fill={theme.palette.common.black} />
        </LinkButton>
      )}
      {instagram && (
        <LinkButton href={instagram}>
          <InstagramIcon size={35} fill={theme.palette.common.black} />
        </LinkButton>
      )}
      {x && (
        <LinkButton href={x}>
          <TwitterIcon size={17} fill={theme.palette.common.black} />
        </LinkButton>
      )}
      {linkedin && (
        <LinkButton href={linkedin}>
          <LinkedinIcon size={35} fill={theme.palette.common.black} />
        </LinkButton>
      )}
      {website && (
        <LinkButton href={`//${website}`}>
          <WorldIcon size={35} fill={theme.palette.common.black} />
        </LinkButton>
      )}
      {email && (
        <LinkButton href={`mailto:${email}`}>
          <EmailIcon size={35} fill={theme.palette.common.black} />
        </LinkButton>
      )}
    </LinksList>
  )
}
