import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'

import { splitNumberWithCommas } from 'src/utils'
import { HoverWrapper } from 'src/components'
import {
  LikesCounterIcon,
  LikesCounterIconFilled,
  LikesCounterSt,
  LikesCounterText,
} from './impact-cards-ver.styled'
import { useSetImpactLike, useUnsetImpactLike } from 'src/hooks/actions'

interface IProps {
  count: number
  userLiked: boolean
  impactId: string
}

export const LikesCounter = ({ count, userLiked, impactId }: IProps) => {
  const [liked, setLiked] = useState(userLiked)
  const { mutate: setLike, isLoading: isLiking } = useSetImpactLike()
  const { mutate: unsetLike, isLoading: isUnliking } = useUnsetImpactLike()
  const [t] = useTranslation()

  const handleToggleLike = () => {
    if (liked) {
      setLiked(false)
      unsetLike(impactId)
      return
    }

    setLiked(true)
    setLike(impactId)
  }

  return (
    <LikesCounterSt>
      <HoverWrapper title={t('impact-likes-tooltip')}>
        <IconButton onClick={handleToggleLike} size="small" disabled={isLiking || isUnliking}>
          {liked ? <LikesCounterIconFilled /> : <LikesCounterIcon />}
        </IconButton>
      </HoverWrapper>
      <LikesCounterText>
        {splitNumberWithCommas(count + (userLiked && !liked ? -1 : !userLiked && liked ? 1 : 0))}
      </LikesCounterText>
    </LikesCounterSt>
  )
}
