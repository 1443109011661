import { useQuery } from '@tanstack/react-query'

import { productService } from 'src/services'
import { QUERY_KEYS } from 'src/constants'

export const useGetProductRedirectUrls = (simpleProductId: string, locale: string) => {
  return useQuery(
    [QUERY_KEYS.GET_PRODUCT_REDIRECT_URLS, simpleProductId, locale],
    () => productService.getProductRedirectUrls(simpleProductId, locale),
    {
      enabled: !!simpleProductId && !!locale,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )
}
