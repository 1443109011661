import { styled } from '@mui/material'

export const PageWrapperSt = styled('div')`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 100%;
  /* min-height: calc(100vh - 90px); */
  max-width: 800px;
`
