import { Typography, styled } from '@mui/material'

export const TitleSt = styled(Typography)<{ isMobile?: boolean }>`
  position: relative;
  /* top: 70px;  */
  width: ${(props) => (props.isMobile ? '70%' : '80%')};
  margin-top: ${({ theme }) => theme.spacing(2.5)};
  margin-left: ${({ theme }) => theme.spacing(1.25)};
  margin-bottom: ${({ theme }) => theme.spacing(4.5)};
`
