import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { JourneyCard, JourneyMap, Loader, PageFooter, PageWrapper } from 'src/components'
import { MapProvider } from 'src/contexts/journey-map.context'
import { useAccount, useImpacts, useProductJourney } from 'src/hooks'
import { TopDivider } from '../product/product.styled'
import { LayoutContainer } from 'src/components/layout-container'
import { PATHS } from 'src/constants'

export default function JourneyPage() {
  const [, i18n] = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { productId, simpleProductId } = useParams()
  const { data, isLoading } = useProductJourney(productId, i18n.language, simpleProductId)

  const { isLoading: isImpactsLoading, isImpacts, product } = useImpacts()
  const { data: account, isLoading: isAccountLoading } = useAccount(
    product?.account_id,
    i18n.language,
  )

  const ref = useRef<HTMLSpanElement | null>(null)
  const topRef = useRef<HTMLSpanElement | null>(null)

  const journey = data?.product?.journey
  const steps = journey?.steps || []

  useEffect(() => {
    if (!isImpactsLoading && product?.name) {
      document.title = `Trackgood Journey ${product?.name} Journey`
    }
  }, [isImpactsLoading, product?.name])

  useEffect(() => {
    if (data && steps && steps.length === 0 && !isLoading) {
      if (location.state?.to) {
        navigate(location.pathname.replace(PATHS.JOURNEY, PATHS.IMPACTS), {
          state: {
            from: location.pathname.replace(`/${PATHS.JOURNEY}`, ''),
            to: `${location.pathname}/${PATHS.IMPACTS}`,
          },
        })

        return
      }
      navigate(location.pathname.replace(`/${PATHS.JOURNEY}`, ''))
      return
    }
  }, [steps, isLoading])

  if (!steps || isLoading || isAccountLoading || !account) {
    return <Loader />
  }

  if (!product || isImpactsLoading) {
    return <Loader />
  }

  return (
    <LayoutContainer>
      {account?.alternative_color && <TopDivider bgColor={account.alternative_color} />}
      <PageWrapper>
        <MapProvider>
          <span ref={topRef} />
          {/* <JourneyMapV2
            steps={steps}
            satelliteView={data?.product?.satellite_map}
            scrollToRef={ref}
          /> */}
          <JourneyMap
            steps={steps}
            satelliteView={account.map_satellite_view}
            scrollToRef={ref}
            markerColor={account?.primary_color}
            isTopDivider={!!account?.alternative_color}
            isImpacts={isImpacts}
            accauntId={product?.account_simple_id}
          />
          <span ref={ref} />
          {/* <JourneyCardV2
            product={product}
            steps={steps}
            journeyName={journey.journey_name}
            topRef={topRef}
          /> */}
          <JourneyCard steps={steps} journeyName={journey.journey_name} topRef={topRef} />
        </MapProvider>
      </PageWrapper>
      <PageFooter />
      {/* <FooterV2 /> */}
    </LayoutContainer>
  )
}
