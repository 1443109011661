import { ReactNode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { LoadScript } from '@react-google-maps/api'

import { QueryClientProvider } from './query-client-provider'
import { ThemeProvider } from './theme-provider'
import './i18n'
import { DrawerProvider } from 'src/contexts/modal-drawer.context'
import { AccountFirstRedirectProvider, BlockchainProvider } from 'src/contexts'

interface IProps {
  children: ReactNode
}

export const AppProviders = ({ children }: IProps) => {
  return (
    <ThemeProvider>
      <QueryClientProvider>
        <BrowserRouter>
          <LoadScript
            loadingElement={<></>}
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          >
            <AccountFirstRedirectProvider>
              <BlockchainProvider>
                <DrawerProvider>{children}</DrawerProvider>
              </BlockchainProvider>
            </AccountFirstRedirectProvider>
          </LoadScript>
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  )
}
