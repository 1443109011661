import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAccount, useProduct } from '../fetch'

const useGoogleAnalytics = () => {
  const [, i18n] = useTranslation()
  const { simpleProductId, accountId } = useParams()

  const { data: product } = useProduct(simpleProductId, i18n.language)
  const { data: account, isLoading } = useAccount(
    accountId ? accountId : product?.account_id,
    i18n.language,
  )

  useEffect(() => {
    if (account && account.analytics_code) {
      ReactGA.initialize(account.analytics_code)
    }
  }, [isLoading])
}

export default useGoogleAnalytics
