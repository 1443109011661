import { Box, useTheme, IconButton, Typography, useMediaQuery, Divider } from '@mui/material'
import { useSwiper } from 'swiper/react'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { BlockchainVerifiedIcon } from 'src/assets/icons/v2/BlockchainVerifiedIcon'

import { WalletAddress } from 'src/components/wallet-address'

interface IProps {
  handlePrev: (scanLength: number) => void
  handleNext: (scanLength: number) => void
  currentIndex: number
  scanLength: number
  primaryColor?: string
  walletAddress: string
  showWalletAddress: boolean
  slot: string
  accountImage?: string
}

export const BlockchainVerifiedModalHeader = ({
  currentIndex,
  scanLength,
  walletAddress,
  primaryColor,
  showWalletAddress,
  slot,
  accountImage,
}: IProps) => {
  const [t] = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const swiper = useSwiper()

  return (
    <div
      slot={slot}
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 102,
        gap: '3px',
        width: '100%',
        maxWidth: '800px',
        backgroundColor: theme.palette.background.paper,
        margin: '0 auto',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '10px',
          padding: '32px 32px 0px',
          paddingBottom: !showWalletAddress && scanLength === 1 ? '32px' : ' 0',
        }}
      >
        {accountImage && !accountImage.includes('https://ui-avatars.com/api') && (
          <Box sx={{ position: 'absolute', left: '32px' }}>
            <Box
              sx={{
                backgroundImage: `url(${accountImage})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                borderRadius: '50%',
                width: '35px',
                height: '35px',
              }}
            />
          </Box>
        )}
        <Typography variant="h6" sx={{ textAlign: 'center', fontSize: isMobile ? '18px' : '24px' }}>
          {t('blockchain-verified-modal-title')}
        </Typography>
        <Box
          sx={{ width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <BlockchainVerifiedIcon size={30} stroke={primaryColor} />
        </Box>
      </Box>

      {showWalletAddress && <WalletAddress address={walletAddress} label={'Account address'} />}

      {scanLength > 1 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: '32px',
            pb: '16px',
          }}
        >
          <IconButton sx={{ p: 0 }} onClick={() => swiper.slidePrev()}>
            <KeyboardArrowLeft fontSize="large" />
          </IconButton>
          <Typography variant="h6" sx={{ textAlign: 'center', flexGrow: 1 }}>
            {currentIndex + 1} of {scanLength}
          </Typography>
          <IconButton sx={{ p: 0 }} onClick={() => swiper.slideNext()}>
            <KeyboardArrowRight fontSize="large" />
          </IconButton>
        </Box>
      )}

      <Divider sx={{ borderColor: 'rgba(0,0,0,0.08);', width: '100%' }} />
    </div>
  )
}
