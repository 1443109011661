import React, {
  MutableRefObject,
  ReactNode,
  createContext,
  useContext,
  useRef,
  useState,
} from 'react'

interface IMapContextProps {
  setMapRef: (map: google.maps.Map) => void
  setMapCenter: (latitude: number, longitude: number, zoom: number) => void
  setScrollRef: (id: string, ref: MutableRefObject<HTMLLIElement | null>) => void
  scrollToRef: (id: string) => void
}

const MapContext = createContext<IMapContextProps | undefined>(undefined)

export const MapProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const mapRef = useRef<google.maps.Map | null>(null)
  const [scrollRefs, setScrollRefs] = useState<
    Record<string, MutableRefObject<HTMLLIElement | null>>
  >({})

  const setMapRef = (map: google.maps.Map) => {
    mapRef.current = map
  }

  const setMapCenter = (latitude: number, longitude: number, zoom: number) => {
    if (mapRef.current) {
      const map = mapRef.current
      map.panTo({ lat: latitude, lng: longitude })
      map.setZoom(zoom)
    }
  }

  const setScrollRef = (id: string, ref: MutableRefObject<HTMLLIElement | null>) => {
    setScrollRefs((prev) => ({ ...prev, [id]: ref }))
  }

  const scrollToRef = (id: string) => {
    const ref = scrollRefs[id]
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const contextValue: IMapContextProps = {
    setMapRef,
    setMapCenter,
    setScrollRef,
    scrollToRef,
  }

  return <MapContext.Provider value={contextValue}>{children}</MapContext.Provider>
}

export const useMap = () => {
  const context = useContext(MapContext)
  if (!context) {
    throw new Error('useMap must be used within a MapProvider')
  }
  return context
}
