import JourneyDetailed from 'src/pages/journey-detailed'
import { IStep } from 'src/types'
import { MutableRefObject } from 'react'

interface IProps {
  steps: IStep[]
  journeyName: string
  topRef?: MutableRefObject<HTMLSpanElement>
}

export const JourneyCard = ({ topRef }: IProps) => {
  return <JourneyDetailed topRef={topRef} />
}
