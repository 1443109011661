import { useLocation, useNavigate } from 'react-router-dom'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, NativeSelect } from '@mui/material'
import moment from 'moment'

import { useProductLocales } from 'src/hooks'
import { DEFAULT_LANGUAGE } from 'src/constants'

export const LocaleSelect = () => {
  const { i18n } = useTranslation()
  const { data: locales } = useProductLocales()
  const location = useLocation()
  const navigate = useNavigate()

  function handleChange(event: ChangeEvent<HTMLSelectElement>) {
    const code = event.target.value
    if (i18n.language === code) return

    let newUrl = location.pathname

    // If the current language is default and we're switching to a side language
    if (i18n.language === DEFAULT_LANGUAGE && code !== DEFAULT_LANGUAGE) {
      newUrl = `/${code}${newUrl}`
    }
    // If switching between side languages
    else if (i18n.language !== DEFAULT_LANGUAGE && code !== DEFAULT_LANGUAGE) {
      newUrl = newUrl.replace(`/${i18n.language}`, `/${code}`)
    }
    // If switching from a side language to the default language
    else if (i18n.language !== DEFAULT_LANGUAGE && code === DEFAULT_LANGUAGE) {
      newUrl = newUrl.replace(`/${i18n.language}`, '')
    }

    navigate(newUrl, { replace: true })
    i18n.changeLanguage(code)
    moment.locale(code)
  }

  if (!locales?.length || locales?.length === 1) {
    return null
  }

  return (
    <FormControl>
      <NativeSelect defaultValue={i18n.language} value={i18n.language} onChange={handleChange}>
        {locales?.map((locale) => (
          <option key={locale.id} value={locale.code}>
            {locale.code.toLocaleUpperCase()}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  )
}
