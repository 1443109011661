import { Box, IconButton, Typography } from '@mui/material'
import { useState } from 'react'
import { CustomModal } from 'src/components/modal'
import { IScanScannerInfo } from 'src/types'
import { EyePinIcon } from 'src/assets/icons/v2/EyePinIcon'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

// interface ILocation {
//   latitude: number
//   longitude: number
// }

interface IBlockchainMapItemProps {
  // locations: ILocation[]
  primaryColor?: string
  scannerInfo: IScanScannerInfo
}

const baseUrl = `https://maps.googleapis.com/maps/api/staticmap?size=600x300&maptype=roadmap`

export const BlockchainMapItem = ({ primaryColor, scannerInfo }: IBlockchainMapItemProps) => {
  const [isMapModalOpen, setIsMapModalOpen] = useState(false)

  const handleMapOpen = () => setIsMapModalOpen(true)
  const handleMapClose = () => setIsMapModalOpen(false)

  const isValidLocation = scannerInfo.latitude !== 0 || scannerInfo.longitude !== 0

  const getGoogleMapUrl = () => {
    const zoomLevel = 7
    const scannerMarkers = `&markers=color:${primaryColor ? primaryColor.replace('#', '0x') : 'red'}%7Clabel:%7C${scannerInfo.latitude / 10000},${scannerInfo.longitude / 10000}`
    return `${baseUrl}${scannerMarkers}&zoom=${zoomLevel}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
  }

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value.toString())
  }

  const formattedLatitude = scannerInfo.latitude / 10000
  const formattedLongitude = scannerInfo.longitude / 10000

  if (!isValidLocation) return null

  return (
    <>
      <IconButton onClick={handleMapOpen} sx={{ p: '3px' }}>
        <EyePinIcon size={30} stroke={primaryColor} />
      </IconButton>

      <CustomModal
        ariaLabel="modal-scan-map"
        open={isMapModalOpen}
        handleClose={handleMapClose}
        showCloseButton
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 5,
            outline: 'none',
            width: '90%',
            maxWidth: '800px',
            height: 'auto',
            maxHeight: '80vh',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* Map Image */}
          <img
            src={getGoogleMapUrl()}
            alt="Static Google Map"
            style={{ width: '100%', height: 'auto' }}
          />

          {/* Latitude and Longitude Information */}
          <Box
            sx={{
              padding: '8px',
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              gap: 3,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="body1">
                Geo-Coords: {formattedLatitude} - {formattedLongitude}
              </Typography>
              <IconButton
                onClick={() =>
                  copyToClipboard(
                    `${scannerInfo.latitude / 10000} ${scannerInfo.longitude / 10000}`,
                  )
                }
                size="small"
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </CustomModal>
    </>
  )
}
