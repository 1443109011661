import { Box, Link } from '@mui/material'

export default function WelcomePage() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
      px={3}
    >
      <Link
        href="https://www.trackgood.io/"
        target="_self"
        rel="noopener"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '& img': {
            width: '100%',
            height: 'auto',
            maxWidth: '600px',
            borderRadius: 1,
            boxShadow: 3,
            transition: 'transform 0.3s, box-shadow 0.3s',
            '&:hover': {
              transform: 'scale(1.02)',
              boxShadow: 6,
            },
          },
        }}
      >
        <img
          src="https://cdn.prod.website-files.com/660f1e6a199b25443c7ab8e8/6639d0a96ef41440f2471f84_trackgood-hero-final-p-800.png"
          alt="Welcome to Trackgood"
        />
      </Link>
    </Box>
  )
}
