import { Typography } from '@mui/material'

import { ContentSt, FooterSt, versionSt } from './page-footer.styled'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAccount, useProduct } from 'src/hooks'

interface IProps {
  accountId?: string
}

export const PageFooter = ({ accountId }: IProps) => {
  const [, i18n] = useTranslation()
  const { productId, simpleProductId } = useParams()

  const { data: product } = useProduct(productId, i18n.language, simpleProductId)
  const { data: account } = useAccount(accountId ? accountId : product?.account_id, i18n.language)

  if (!account) {
    return null
  }

  return (
    <>
      {account?.primary_color || account?.footer || account?.footer_url ? (
        <FooterSt primaryColor={account?.primary_color}>
          <ContentSt>
            {account?.qr_code_logo && (
              <img
                alt="Footer logo"
                src={account?.qr_code_logo}
                style={{ height: '30px', width: 'auto' }}
              />
            )}
            {account?.footer_url && account?.footer && (
              <a
                href={account.footer_url}
                target="_blank"
                style={{
                  textDecoration: 'underline',
                  color: 'inherit',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  className={versionSt}
                  style={{
                    textDecoration: 'underline',
                    fontSize: '12px',
                    color: account?.footer_color ? account?.footer_color : 'inherit',
                  }}
                >
                  {account?.footer}
                </Typography>
              </a>
            )}
            {!account?.footer_url && account?.footer && (
              <Typography
                className={versionSt}
                style={{
                  fontSize: '12px',
                  color: account?.footer_color ? account?.footer_color : 'inherit',
                }}
              >
                {account?.footer}
              </Typography>
            )}

            {/* <Typography className={versionSt} variant="body2">
            v{process.env.REACT_APP_VERSION}
          </Typography> */}
          </ContentSt>
        </FooterSt>
      ) : null}
    </>
  )
}
