import { styled } from '@mui/material'

export const ContainerSt = styled('div')`
  display: flex;
  justify-content: center;
  height: 55vh;
  /* height: 67vh; */
  /* margin-top: -75px; */

  text-align: center;

  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down('md')} {
    /* position: sticky; */
    z-index: 0;
    left: 0;
    top: 0;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    position: relative;
  }
`

export const MapWrapper = styled('div')`
  width: 100%;
  z-index: 0;
  /* margin-top: 12vh; */

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 55vh;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 55vh;
  }
`
