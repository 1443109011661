import { Box, Tooltip } from '@mui/material'

import { BlockchainVerifiedIcon } from 'src/assets/icons/v2/BlockchainVerifiedIcon'
import { IScan } from 'src/types'
import { BlockchainVerifiedModal } from './blockchain-modal'
import { useBlockchainVerifiedModal } from 'src/hooks'
import { useTranslation } from 'react-i18next'

interface IProps {
  size?: string | number
  stroke?: string
  scans: IScan[]
  walletAddress: string
  showWalletAddress: boolean
  accountImage?: string
  scanImage?: string
  scanName?: string
  impactImage?: string
  impactName?: string
}

export const BlockchainVerifiedBanner = ({
  size,
  stroke,
  scans,
  walletAddress,
  showWalletAddress,
  accountImage,
  scanImage,
  scanName,
  impactImage,
  impactName,
}: IProps) => {
  const [t] = useTranslation()
  const { currentIndex, handleClose, handleNext, handleOpen, handlePrev, open, setCurrentIndex } =
    useBlockchainVerifiedModal()

  return (
    <>
      <Tooltip
        title={t('blockchain-verified-banner-tooltip')}
        slotProps={{
          popper: {
            sx: { div: { borderRadius: '4px' } },
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -5],
                },
              },
            ],
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '-15px',
            right: '-15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            transition: 'transform 200ms',
            willChange: 'transform',
            '&:hover': {
              transform: 'scale(0.9)',
            },
            '&:active': {
              // Trigger tooltip on mobile hold
              transform: 'scale(0.9)',
            },
          }}
          onClick={handleOpen}
          className="blockchain-verified-banner"
          zIndex={10}
        >
          <BlockchainVerifiedIcon size={size} stroke={stroke} />
        </Box>
      </Tooltip>

      <BlockchainVerifiedModal
        currentIndex={currentIndex}
        handleClose={handleClose}
        handleNext={handleNext}
        handlePrev={handlePrev}
        open={open}
        scans={scans}
        setCurrentIndex={setCurrentIndex}
        primaryColor={stroke}
        walletAddress={walletAddress}
        showWalletAddress={showWalletAddress}
        accountImage={accountImage}
        scanImage={scanImage}
        scanName={scanName}
        impactImage={impactImage}
        impactName={impactName}
      />
    </>
  )
}
