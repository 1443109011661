import { useAccount, useAccountImpacts, useProductImpacts } from 'src/hooks'
import { IImpact, IProduct } from 'src/types'

import { Content } from './content.component'
import { useTranslation } from 'react-i18next'

interface IProps {
  product: IProduct
  primaryColor: string
}

export const ProductCard = ({ product, primaryColor }: IProps) => {
  const [, i18n] = useTranslation()
  const { data: account } = useAccount(product.account_id, i18n.language)
  const { data } = useProductImpacts(product.id, i18n.language)
  const { data: accountImpacts } = useAccountImpacts(i18n.language, null, product.id)

  const impacts = data?.impacts || []
  const accImpacts = accountImpacts || []

  const allImpacts = [...product.impacts, ...impacts, ...accImpacts]

  const filteredImpacts = allImpacts.filter((impact) => impact.home_page_show)
  const isImpactsPage = allImpacts.filter((impact) => impact.impacts_page_show).length > 0

  const uniqueImpacts = {}

  filteredImpacts.forEach((obj) => {
    if (!uniqueImpacts[obj.id]) {
      uniqueImpacts[obj.id] = obj
    }
  })

  const impactsToShow = Object.values(uniqueImpacts) as IImpact[]

  if (!account) return null

  return (
    <Content
      media={product.media}
      productDescription={product.description}
      productName={product.name}
      accountImage={account.image_small || account.image}
      accountHolder={account.account_holder}
      impacts={impactsToShow}
      isImpactsPage={isImpactsPage}
      showMedia={product.showMedia}
      hideAccountName={account.hide_name}
      hideProductName={account.hide_product_name}
      accountId={product.account_simple_id}
      primaryColor={primaryColor}
      walletAddress={account.wallet_address}
      showWalletAddress={account.show_wallet_address}
      showBcInfo={account.show_bc_info}
    />
  )
}
