import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS, STORAGE_KEYS } from 'src/constants'
import { productService } from 'src/services'

export const useProduct = (
  productId: string | undefined,
  locale: string,
  simpleProductId?: string,
) => {
  return useQuery(
    [QUERY_KEYS.GET_PRODUCT, productId || simpleProductId, locale],
    () => {
      return productService.getProduct(
        productId,
        locale,
        localStorage.getItem(STORAGE_KEYS.GUEST_ID),
        simpleProductId,
      )
    },
    {
      enabled: !!productId || !!simpleProductId,
    },
  )
}
