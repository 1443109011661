import { CustomModal } from '.'

interface IProps {
  handleClose: () => void
  imageSrc: string
}

export const ImageModal = ({ handleClose, imageSrc }: IProps) => {
  return (
    <CustomModal
      ariaLabel="modal-image"
      open={!!imageSrc}
      handleClose={handleClose}
      closeOnParentClick
      showCloseButton
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        objectFit: 'contain',
        height: '90%',
        width: '90%',
      }}
    >
      <img
        onClick={(e) => {
          e.stopPropagation()
        }}
        src={imageSrc}
        alt="Fullscreen view"
        style={{ maxHeight: '100%', maxWidth: '100%' }}
      />
    </CustomModal>
  )
}
