import { useLocation } from 'react-router-dom'

import { MenuBurger } from '../menu-burger'
import { NextPage } from '../navigation'
import { UnSdgList } from './un-sdg-list.component'
import { MediaCarousel } from './media-carousel.component'

import { IMedia, IUnSdg } from 'src/types'
import { PATHS, ROUTES } from 'src/constants'
import { ImageSt, ImageWrapper } from './product-image.styled'

interface IProps {
  name: string
  image: string
  media: IMedia[]
  unSdg: IUnSdg[]
}

export const ProductImage = ({ name, image, unSdg, media }: IProps) => {
  const location = useLocation()

  return (
    <ImageWrapper withIndicator={media.length > 1}>
      {media.length ? <MediaCarousel media={media} /> : <ImageSt alt={name} src={image} />}
      <UnSdgList unSdg={unSdg} withIndicator={media.length > 1} />
      <MenuBurger />
      <NextPage
        to={`${location.pathname}/${ROUTES.JOURNEY}`}
        state={{ from: location.pathname, to: `${location.pathname}/${PATHS.JOURNEY}` }}
      />
    </ImageWrapper>
  )
}
