import { Typography, Menu, useTheme } from '@mui/material'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import { useTranslation } from 'react-i18next'

import { HeartIcon, HomeIcon, PinAddIcon, ProfileIcon } from 'src/assets/icons'
import { PopUpItem } from './pop-up-item.component'
import { PopUpLink } from './pop-up-link.component'

import { BASE_API_URL, DEFAULT_LANGUAGE, ROUTES } from 'src/constants'
import { useImpacts, useLogout, useMe, useProductJourney, useProductLocales } from 'src/hooks'
import { convertToBase64Url } from 'src/utils'
import { PopUpUser } from './pop-up-user.component'
import ArrowIconLeft from 'src/assets/icons/arrow.icon-left'
import { COLORS } from 'src/styles/common'
import { LocaleSelect } from '../locale-select'
import { WorldIcon } from 'src/assets/icons/v2/WorldIcon'
import { useLocation, useParams } from 'react-router-dom'
import { PopUpLinkItem } from './pop-up-link-item.component'

interface IProps {
  open: boolean
  anchorEl: HTMLElement | null
  handleClose: () => void
}

export const MenuPopUp = ({ open, anchorEl, handleClose }: IProps) => {
  const [t, i18n] = useTranslation()
  const location = useLocation()
  const { simpleProductId } = useParams()
  const { isLoading: isImpactsLoading, isImpacts, product } = useImpacts()
  const { data, isLoading: isProductJourneyLoading } = useProductJourney(
    product.id,
    i18n.language,
    simpleProductId,
  )
  const { data: me, isLoading } = useMe()
  const logOut = useLogout()
  const theme = useTheme()

  const handleLogout = async () => {
    await logOut()
    handleClose()
    window.location.reload()
  }
  const { data: locales, isLoading: isLocalesLoading } = useProductLocales()

  const from = convertToBase64Url(
    window.location.origin +
      window.location.pathname +
      (product?.account_id ? `&account_id=${product?.account_id}` : ''),
  )

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      // sx={{ top: -6, left: isTablet ? -6 : (window.innerWidth - 800) / 2 }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handleClose}
    >
      <PopUpItem icon={<ArrowIconLeft />} onClick={handleClose} text="Back" />
      <PopUpLinkItem
        icon={<HomeIcon />}
        text={t('home-header-title')}
        to={location.pathname}
        state={{ from: location.pathname }}
      />
      {!isProductJourneyLoading &&
        data?.product?.journey?.steps &&
        data?.product?.journey?.steps.length > 0 && (
          <PopUpLinkItem
            icon={<PinAddIcon />}
            text={t('journey-header-title')}
            to={`${location.pathname}/${ROUTES.JOURNEY}`}
            state={{ from: location.pathname }}
          />
        )}
      {isImpacts && (
        <PopUpLinkItem
          icon={<HeartIcon />}
          text={t('impacts-header-title')}
          to={`${location.pathname}/${ROUTES.IMPACTS}`}
          state={{ from: location.pathname }}
        />
      )}
      {!isImpactsLoading && product?.account_id && (
        <PopUpLinkItem
          icon={<ProfileIcon />}
          text={t('account-header-title')}
          to={
            i18n.language === DEFAULT_LANGUAGE
              ? `/${ROUTES.ACCOUNT}/${product?.account_simple_id}`
              : `/${i18n.language}/${ROUTES.ACCOUNT}/${product?.account_simple_id}`
          }
          state={{ from: location.pathname, productId: simpleProductId }}
          divider
        />
      )}

      {me && !isLoading && (
        <PopUpUser title={me.name || me.email} image={me.image_small || me.image} />
      )}
      {/* {me && !isLoading && locales && locales.length > 1 && (
        <PopUpItem
          onClick={() => false}
          icon={<WorldIcon size={24} stroke={theme.palette.common.black} />}
          text={<LocaleSelect />}
          padding="5px 16px"
          divider
        />
      )} */}

      {me && !isLoading && (
        <PopUpItem
          icon={<LogoutIcon color="secondary" />}
          text={t('log-out-button-text')}
          onClick={handleLogout}
          divider={locales && locales.length > 1}
        />
      )}

      {!me && !isLoading && (
        <PopUpLink
          icon={<LoginIcon color="secondary" />}
          text={t('log-in-button-text')}
          link={`${BASE_API_URL}auth/journey/sign-in/${from}`}
          //?For development
          // link={`http://localhost:4000/api/auth/journey/sign-in/${from}`}
          divider={locales && locales.length === 1}
        />
      )}
      {/* {!me && !isLoading && locales && locales.length > 1 && (
        <PopUpItem
          onClick={() => false}
          icon={<WorldIcon size={24} stroke={theme.palette.common.black} />}
          text={<LocaleSelect />}
          padding={`5px 16px ${product?.settings?.sign_up_enabled ? '5px' : '16px'}`}
          divider
        />
      )} */}
      {!me && !isLoading && product?.settings?.sign_up_enabled && (
        <PopUpLink
          icon={<ProfileIcon />}
          text={t('sign-up-button-text')}
          link={`${BASE_API_URL}auth/journey/register/${from}`}
          //?For development
          // link={`http://localhost:4000/api/auth/journey/register/${from}`}
          divider={locales && locales.length > 1}
        />
      )}

      {!isLocalesLoading && locales && locales.length > 1 && (
        <PopUpItem
          onClick={() => false}
          icon={<WorldIcon size={24} stroke={theme.palette.common.black} />}
          text={<LocaleSelect />}
          padding={`12px 16px `}
          // divider
        />
      )}

      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          fontSize: '10px',
          color: COLORS.GREY_LIGHT,
        }}
      >
        {process.env.REACT_APP_VERSION || 'v0.2.225'}
      </Typography>
    </Menu>
  )
}
