import LanguageDetector from 'i18next-browser-languagedetector'
import { LANGUAGES } from 'src/constants'
// import { STORAGE_KEYS } from 'src/constants'

const urlLngDetector = {
  name: 'urlDetector',

  lookup() {
    // const storageLng = localStorage.getItem(STORAGE_KEYS.I18N_LNG)
    // const { fallbackLng = storageLng || 'en' } = options

    const path = window.location.pathname.split('/')

    // Check if the first path segment is an integer
    const possibleLocale = path[1]

    if (!LANGUAGES.includes(possibleLocale) || possibleLocale === 'account') {
      return 'en'
    }

    return possibleLocale
  },
}

const languageDetector = new LanguageDetector()
languageDetector.addDetector(urlLngDetector)

export default languageDetector
