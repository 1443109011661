import { Typography, styled } from '@mui/material'
import { HeartFilledIcon, HeartIcon } from 'src/assets/icons'

export const List = styled('ul')(
  ({ theme }) => `
  padding: ${theme.spacing(1.25)} 0 ${theme.spacing(1.25)} 0;
  position: relative;
  // top: 40px;


  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(5.5)};
  margin-bottom: 20px;
`,
)

export const Item = styled('li')`
  position: relative;
  max-width: 100%;
  height: 100%;

  border-radius: 15px;
  background: ${({ theme }) => theme.palette.background.default};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
`

export const UpperWrapper = styled('div')`
  padding: ${({ theme }) => theme.spacing(2.5)};

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2.5)};
`

export const CarouselWrapper = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(1.25)};
`

export const BottomWrapper = styled('div')(
  ({ theme }) => ` 
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1.25)};
  margin-bottom: 24px;
`,
)

export const ItemImage = styled('div')(
  () => `
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
`,
)

export const ItemTextWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  column-gap: 5px;
  align-items: baseline;
  padding-bottom: ${({ theme }) => theme.spacing(0.5)};
`

export const ItemText = styled(Typography)`
  margin: 10px 5px 10px 0px;
`

export const DescriptionText = styled(Typography)`
  margin: 10px 12px;
`

export const ItemTitle = styled(Typography)`
  font-size: 36px;
`

export const LikesCounterSt = styled('div')`
  display: flex;
  margin: 0px 12px;
  gap: ${({ theme }) => theme.spacing(0.5)};
  align-items: center;
`

export const LikesCounterIcon = styled(HeartIcon as any)`
  width: 15px;
  height: 15px;
  fill: ${({ theme }) => theme.palette.secondary.main};
`

export const LikesCounterIconFilled = styled(HeartFilledIcon as any)`
  width: 15px;
  height: 15px;
  fill: #ff005c;
`

export const LikesCounterText = styled(Typography)`
  font-size: 10px;
  font-weight: 600;
  line-height: 1.4;
`

export const ShareWrapper = styled('div')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.25)};
  justify-content: flex-end;
  margin: 12px;
`
