import { Avatar, ListItem, ListItemText } from '@mui/material'

interface IProps {
  image: string
  title: string
}

export const PopUpUser = ({ title, image }: IProps) => {
  return (
    <ListItem style={{ gap: 10, padding: '16px' }}>
      <Avatar sx={{ width: 25, height: 25 }} alt={title} src={image} />
      <ListItemText> {title}</ListItemText>
    </ListItem>
  )
}
