import { Link } from 'react-router-dom'
import { LeftButton, PrevPageIcon } from './navigation.styled'

type PreviousPageProps = {
  to: string
  state?: object
  absolute?: boolean
  isImpactPage?: boolean
  isTopDivider?: boolean
}

export const PreviousPage = ({
  to,
  state,
  absolute,
  isImpactPage,
  isTopDivider,
}: PreviousPageProps) => {
  return (
    <Link to={to} state={state ? state : null}>
      <LeftButton
        size="medium"
        aria-label="Previous Page"
        absolute={absolute}
        isImpactPage={isImpactPage}
        isTopDivider={isTopDivider}
      >
        <PrevPageIcon />
      </LeftButton>
    </Link>
  )
}
