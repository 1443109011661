import { MutableRefObject, useEffect, useRef, createRef } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'

import { useMap } from 'src/contexts/journey-map.context'
import { BlockchainVerifiedBanner, JourneyStepCard } from 'src/components'
import { IStep, Ilocation } from 'src/types'

import {
  CardWrapper,
  ItemSt,
  LastStepIcon,
  LogCircle,
  LogWrapper,
  StepsList,
} from './journey-step.styled'

interface IProps {
  steps: IStep[]
  detailed?: boolean
  topRef?: MutableRefObject<HTMLSpanElement>
  hideAccountName: boolean
  primaryColor?: string
  secondaryColor?: string
  walletAddress: string
  showWalletAddress: boolean
  showBcInfo: boolean
  accountImage: string
}

export const JourneySteps = ({
  steps,
  detailed,
  topRef,
  hideAccountName,
  primaryColor,
  walletAddress,
  showWalletAddress,
  showBcInfo,
  accountImage,
}: IProps) => {
  const { setMapCenter, setScrollRef } = useMap()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const stepRefs = useRef<Record<string, MutableRefObject<HTMLLIElement | null>>>({})

  useEffect(() => {
    // Register step refs in the context
    steps.forEach((step) => {
      if (!stepRefs.current[step.id]) {
        stepRefs.current[step.id] = createRef<HTMLLIElement>()
        setScrollRef(step.id, stepRefs.current[step.id])
      }
    })
  }, [steps, setScrollRef])

  const length = steps.length - 1

  const handLocationScroll = (item: Ilocation) => {
    if (!item.location_address || !item.latitude || !item.longitude) {
      return
    }

    if (topRef.current) {
      setMapCenter(item.latitude / 10_000, item.longitude / 10_000, 14)

      if (isMobile) {
        window.scrollTo({
          top: topRef.current.offsetTop,
          behavior: 'smooth',
        })
      } else {
        topRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }

  return (
    <StepsList>
      {steps.map((item, i) => (
        <ItemSt
          key={item.id}
          ref={stepRefs.current[item.id]}
          // onClick={() => {
          //   setMapCenter(item.latitude / 10_000, item.longitude / 10_000, 5)
          // }}
        >
          <CardWrapper isLastStep={length === i}>
            <LogWrapper>
              {length === i ? (
                <LastStepIcon sx={{ fill: primaryColor }} color="primary" />
              ) : (
                <LogCircle />
              )}
              {/* <JourneyLogButton step={item} isMobile={isMobile} /> */}
            </LogWrapper>
            <JourneyStepCard
              handleScroll={handLocationScroll}
              email={item.email}
              account_approved={item.account_approved}
              isMobile={isMobile}
              mainMedia={detailed ? item.mainMedia : undefined}
              image={item.image}
              name={item.name}
              landing_page_show={item.landing_page_show}
              impacts={item.impacts}
              account_holder={item.account_holder}
              image_small={item.image_small || item.image_medium}
              media={detailed ? item.media : undefined}
              description={detailed ? item.description : undefined}
              website={item.website}
              hideAccountName={hideAccountName}
              locations={item.locations}
            />
          </CardWrapper>
          {showBcInfo && item.scans && item.scans.length > 0 && (
            <BlockchainVerifiedBanner
              size={40}
              scans={item.scans}
              stroke={primaryColor}
              walletAddress={walletAddress}
              showWalletAddress={showWalletAddress}
              accountImage={accountImage}
              scanImage={item?.image_medium || item?.image_small}
              scanName={item.name}
            />
          )}
        </ItemSt>
      ))}
    </StepsList>
  )
}
