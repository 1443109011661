import { css } from '@emotion/css'
import { Theme, styled } from '@mui/material'

export const MediaListSt = styled('ul')`
  margin: 10px;

  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  /* 
  overflow-x: scroll;


  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none; */
`

export const ItemSt = styled('li')`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const LinkSt = styled('div')<{ size?: number }>`
  display: block;
  width: ${({ size }) => (size ? `${size}px` : '45px')};
  height: ${({ size }) => (size ? `${size}px` : '45px')};
`

export const mediaSt = (theme: Theme) => css`
  && {
    width: inherit;
    height: inherit;
    fill: ${theme.palette.text.primary};

    object-fit: cover;
    border-radius: 6px;
  }
`

export const TitleSt = styled('span')`
  inline-size: 50px;
  overflow-wrap: break-word;
  text-align: center;

  font-size: 0.6rem;
`
