import { IImpact } from 'src/types'

import { ImpactCard } from './impact-card.component'
import { List } from './impact-cards-ver.styled'
import { useState } from 'react'
import { TitleSt } from 'src/pages/impacts/impacts.styled'
import { useTranslation } from 'react-i18next'

interface IProps {
  items: IImpact[]
  showUnSdg: boolean
  primaryColor: string
  walletAddress: string
  showWalletAddress: boolean
  showBcInfo: boolean
  accountImage: string
}

export const ImpactsCardsVer = ({
  items,
  showUnSdg,
  primaryColor,
  walletAddress,
  showWalletAddress,
  showBcInfo,
  accountImage,
}: IProps) => {
  const [isMobile] = useState<boolean>(window.innerWidth < 770)
  const [t] = useTranslation()

  return (
    <>
      <TitleSt sx={{ marginTop: '90px' }} isMobile={isMobile} variant="h2">
        {t('impacts-header-title')}
      </TitleSt>

      <List>
        {items.map((item) => (
          <ImpactCard
            unSdg={item.un_sdg}
            key={item.id}
            media={item.media}
            mainMedia={item.mainMedia}
            description={item.description}
            title={item.quantity}
            subtitle={item.name}
            image={item.image_medium || item.image}
            likes={item.totalLikes}
            liked={item.liked}
            impactId={item.id}
            quantityName={item.quantity_type_name}
            showUnSdg={showUnSdg}
            scans={item.scans}
            primaryColor={primaryColor}
            walletAddress={walletAddress}
            showWalletAddress={showWalletAddress}
            showBcInfo={showBcInfo}
            accountImage={accountImage}
          />
        ))}
      </List>
    </>
  )
}
