import { Avatar, styled } from '@mui/material'

export const WrapperSt = styled('div')`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.25)};
`

export const AvatarSt = styled('div')`
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  /* background-color: ${({ theme }) => theme.palette.background.paper}; */

  /* border-radius: 6px; */
  width: 48px;
  height: 48px;
`
export const AvatarImg = styled('img')<{
  width?: string
  borderRadius?: string
  height?: string
}>`
  border-radius: ${(props) => props.borderRadius ?? '0px'};
  width: ${(props) => props.width ?? 'auto'};
  height: ${(props) => props.height ?? 'auto'};
  min-width: ${(props) => props.width ?? 'auto'};
  /* object-position: center;
  object-fit: contain; */
`

export const AvatarAccount = styled(Avatar)`
  background-color: ${({ theme }) => theme.palette.background.paper};

  border-radius: 50%;
  width: 60px;
  height: 60px;
`

export const AccountHolder = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-left: 5px;
  gap: 10px;
`

export const Divider = styled('span')`
  flex-grow: 1;
`

export const DescriptionHolder = styled('div')<{ isLeft?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isLeft ? 'start' : 'end')};
`
