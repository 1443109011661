import { ReactElement } from 'react'
import { Tooltip } from '@mui/material'

interface IProps {
  title: string
  children: ReactElement
}

export const HoverWrapper = ({ title, children }: IProps) => {
  return (
    <Tooltip
      slotProps={{
        tooltip: {
          style: {
            borderRadius: '4px',
          },
        },
      }}
      title={title}
    >
      {children}
    </Tooltip>
  )
}
