import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Avatar, Typography } from '@mui/material'

import { imgSt, titleWrapper, wrapper, wrapperMobile } from './bottom-card-title.styled'
import { useNavigateWithParams } from 'src/hooks/common/use-navigate-with-params.hook'
import { DEFAULT_LANGUAGE, ROUTES } from 'src/constants'

interface IProps {
  title: string
  image: string
  twitterTitle: string
  hideAccountName?: boolean
  accountId: string
}

export const BottomCardTitle = ({ title, image, hideAccountName, accountId }: IProps) => {
  const [, i18n] = useTranslation()
  const location = useLocation()
  const isMobile = innerWidth < 770
  const customNav = useNavigateWithParams()
  const handleNavigate = (to: string) => () => customNav(to)

  return (
    <div className={isMobile ? wrapperMobile : wrapper}>
      <Link
        to={
          i18n.language === DEFAULT_LANGUAGE
            ? `/${ROUTES.ACCOUNT}/${accountId}`
            : `/${i18n.language}/${ROUTES.ACCOUNT}/${accountId}`
        }
        state={{ from: location.pathname }}
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        <div className={titleWrapper} onClick={handleNavigate(ROUTES.ACCOUNT)}>
          <Avatar className={imgSt} alt={title} src={image} />
          {!hideAccountName && <Typography variant="h5">{title}</Typography>}
        </div>
      </Link>
      {/* <span className={titleDivider}/>
      <ShareButton />
      <TwitterShare title={twitterTitle} /> */}
    </div>
  )
}
