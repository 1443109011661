import { styled, Fab } from '@mui/material'

export const FabSt = styled(Fab)`
  && {
    position: fixed;
    bottom: 10px;
    right: 70px;
    transform: translateX(50%);
    z-index: 2299;

    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 35px;
    height: 40px;
    width: 40px;
    padding: 0;

    border-radius: 50%;

    line-height: 1.3;
    font-size: ${({ theme }) => theme.typography.pxToRem(10)};
    font-weight: 500;
    text-transform: uppercase;
    color: ${({ theme }) => theme.palette.background.default};

    & > svg {
      /* margin-left: -3px;
      margin-right: 6px; */

      fill: ${({ theme }) => theme.palette.background.default};
    }
  }
`
