import { Avatar, styled } from '@mui/material'
import { css } from '@emotion/css'

export const ImageSt = styled('img')`
  width: 100%;
  height: 55vh;

  object-fit: contain;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    object-fit: cover;
  }
`

export const VideoSt = styled('video')`
  width: 100%;
  height: 55vh;
  object-fit: contain;
  display: block;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    object-fit: cover !important;
  }
`

export const ImageWrapper = styled('div')<{ withIndicator: boolean }>`
  height: ${({ withIndicator }) => (withIndicator ? '60vh' : '55vh')};
  justify-content: center;

  text-align: center;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down('md')} {
    /* position: sticky; */
    z-index: 0;
    left: 0;
    top: 0;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    position: relative;
  }
`

export const List = styled('ul')<{ withIndicator: boolean }>`
  align-content: flex-end;
  height: 200px;
  width: calc(100% - 32px);
  margin-left: 16px;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: ${({ withIndicator }) => (withIndicator ? '-245px' : '-216px')};
  gap: ${({ theme }) => theme.spacing(1)};

  ${({ theme }) => theme.breakpoints.down('md')} {
    bottom: 24px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    bottom: 10px;
  }
`

export const ListImage = styled(Avatar)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 40px;
    height: 40px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 44px;
    height: 44px;
  }

  border-radius: 6px;
`

export const mediaCarousel = css`
  & > div > div > div > div {
    position: static !important;
  }
`

export const navButtonWrapper = css`
  margin: 0 10px;
  :hover {
    button {
      background-color: #000;
      filter: brightness(120%);
      opacity: 0.8 !important;
    }
  }
  :hover {
    button:hover {
      /* opacity: 0.8 !important; */
    }
  }
`
export const navIcon = css`
  color: white;
  /* opacity: 0.8; */
  transition: all 0.3s ease-in-out;
  :hover {
    /* opacity: 0.8; */
  }
`
export const indicatorWrapper = css`
  margin-top: 15px !important;
`
