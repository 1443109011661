import { Typography, styled } from '@mui/material'
import { Link } from 'react-router-dom'

export const List = styled('div')<{ withArrows?: boolean }>(
  ({ theme, withArrows }) => `
  padding: 15px ${!withArrows ? '30px' : '0'} ${theme.spacing(1.25)};
  width: 85vw;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 0.5em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  display: flex;

  gap: ${theme.spacing(2.5)};
`,
)

export const Item = styled('div')<{ disabled?: boolean }>(
  ({ disabled }) => `
  height: auto;

  transition: transform 200ms, filter 200ms;
  will-change: transform, filter;
  &:hover {
    filter:${disabled ? '' : 'brightness(90%)'} ;
    transform:${disabled ? '' : 'scale(0.98)'} ; 
  }
`,
)

export const LinkSt = styled(Link)(
  ({ theme }) => `
  width: 120px;
  max-height: 200px;
  min-height: 165px;

  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 7px;
  background: ${theme.palette.background.default};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);

  overflow: hidden;

  text-decoration: none;
  color: inherit;
`,
)

export const ItemBottom = styled('div')<{ isQuantity: boolean }>`
  display: flex;
  height: 100%;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ isQuantity }) => (isQuantity ? 'flex-start' : 'center')};

  padding-left: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(1)};
  padding-bottom: ${({ theme }) => theme.spacing(0.5)};
  padding-top: ${({ theme }) => theme.spacing(0.5)};
`

export const ItemTextWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 2px;
  align-items: baseline;
  padding-bottom: ${({ theme }) => theme.spacing(0.5)};
`

export const ItemImage = styled('div')(
  () => `
  width: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 110px;
`,
)
export const ItemText = styled(Typography)(
  () => `
  text-overflow: ellipsis;
  text-align: center;
  word-break: break-word;
`,
)

export const UnSdgList = styled('div')(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing(0.5)};
  flex-wrap: wrap;
  align-items: center;
`,
)
