import { useState } from 'react'

export const useBlockchainVerifiedModal = () => {
  const [open, setOpen] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleOpen = () => {
    setOpen(true)
    setCurrentIndex(0) // Reset to the first item when opening
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handlePrev = (scanLength: number) => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? scanLength - 1 : prevIndex - 1))
  }

  const handleNext = (scanLength: number) => {
    setCurrentIndex((prevIndex) => (prevIndex === scanLength - 1 ? 0 : prevIndex + 1))
  }

  return {
    open,
    setOpen,
    currentIndex,
    setCurrentIndex,
    handleOpen,
    handleClose,
    handlePrev,
    handleNext,
  }
}
