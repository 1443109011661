import { useNavigate, useParams } from 'react-router-dom'

export const useNavigateWithParams = () => {
  const { productId, lng } = useParams()
  const navigate = useNavigate()

  return (route: string, state?: object) => {
    if (!route) {
      navigate(`/${lng}/product/${productId}`, { state, replace: true })
      return
    }
    navigate(`/${lng}/product/${productId}/${route}`, { state, replace: true })
  }
}
