import axios from 'axios'

import { ICurrentUserResponse, IRefreshTokenResponse, IUserAuthResponse } from 'src/types'
import { BASE_API_URL } from 'src/constants'

import { HttpService } from './http.service'

class AuthService extends HttpService {
  constructor() {
    super(axios, BASE_API_URL)
  }

  async refreshToken(token: string) {
    return this.put<IRefreshTokenResponse, null>(`auth/refresh/${token}`, null)
  }

  async checkIsAuthenticated(accessToken: string) {
    return this.get<IUserAuthResponse>('auth', {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
  }

  async getJourneyUser(accessToken: string, productId: string) {
    return this.get<ICurrentUserResponse>('users/journey', {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { productId },
    })
  }

  async validateToken(token: string, accessToken: string) {
    return this.get<{ status: boolean }>(`token-vault/token-validate/${token}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
  }

  async deleteTokens(accessToken: string, refreshToken: string) {
    await this.delete<void>(`token-vault/token/${refreshToken}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
    await this.delete<void>(`token-vault/token/${accessToken}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
  }
}
export const authServie = new AuthService()

export default AuthService
