import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from 'src/constants'
import { accountService } from 'src/services'

export const useAccountImpacts = (locale: string, accountId?: string, productId?: string) => {
  return useQuery(
    [QUERY_KEYS.GET_ACCOUNT_IMPACTS, accountId || productId, locale],
    () => accountService.getAccountImpacts(locale, accountId, productId),

    {
      enabled: !!accountId || !!productId,
      keepPreviousData: true,
    },
  )
}
