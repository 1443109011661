import { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useState } from 'react'

interface IDrawer {
  isRedirected: boolean
  setIsRedirected: Dispatch<SetStateAction<boolean>>
}

export const AccountFirstRedirectContext = createContext<IDrawer | null>(null)

export const AccountFirstRedirectProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isRedirected, setIsRedirected] = useState(false)

  return (
    <AccountFirstRedirectContext.Provider
      value={{
        isRedirected,
        setIsRedirected,
      }}
    >
      {children}
    </AccountFirstRedirectContext.Provider>
  )
}

export const uselAccountFirstRedirect = () => {
  const context = useContext(AccountFirstRedirectContext)
  if (!context) {
    throw new Error('useDrawerer must be used within a DrawerProvider')
  }
  return context
}
