import { ReactNode } from 'react'
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material'

interface IProps {
  icon: ReactNode
  text?: string | ReactNode
  onClick: () => void
  divider?: boolean
  padding?: string
}

export const PopUpItem = ({ icon, text, onClick, divider, padding }: IProps) => {
  return (
    <MenuItem
      sx={{ padding: padding ? padding : 2, width: '180px' }}
      aria-label="Home"
      onClick={onClick}
      divider={divider}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      {text && <ListItemText>{text}</ListItemText>}
    </MenuItem>
  )
}
