import { useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

const MAX_RETRIES = 4
const RETRY_DELAY = 6000

export const usePdfModal = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [pdfPreview, setPdfPreview] = useState<string | null>(null)
  const [isPdfVisible, setIsPdfVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [loadError, setLoadError] = useState(false)
  const [retryCount, setRetryCount] = useState(0)

  const iframeRef = useRef<HTMLIFrameElement | null>(null)

  const retryLoadIframe = () => {
    if (retryCount < MAX_RETRIES) {
      const timeout = setTimeout(() => {
        if (iframeRef.current) {
          setRetryCount(retryCount + 1)
          iframeRef.current.src = `https://docs.google.com/viewer?url=${pdfPreview || ''}&embedded=true&t=${new Date().getTime()}`
        }
      }, RETRY_DELAY)
      return () => clearTimeout(timeout)
    } else {
      setLoadError(true)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (pdfPreview) {
      setIsLoading(true)
      setLoadError(false)
      retryLoadIframe()
    }
  }, [pdfPreview])

  useEffect(() => {
    if (retryCount > 0 && retryCount <= MAX_RETRIES && isLoading) {
      retryLoadIframe()
    } else if (retryCount > MAX_RETRIES) {
      setLoadError(true)
      setIsLoading(false)
    }
  }, [retryCount, isLoading])

  const handleLoad = () => {
    setIsLoading(false)
    setRetryCount(0)
  }
  const handleLoadStart = () => {
    setIsLoading(true)
    retryLoadIframe()
    setRetryCount(0)
  }

  const handleError = () => {
    setRetryCount((prevCount) => prevCount + 1)
  }

  const handleClosePdfPreview = () => {
    if (!isMobile) {
      setPdfPreview(null)
    }
    // setIsLoading(false)
    setLoadError(false)
    setRetryCount(0)
    setIsPdfVisible(false)
  }

  return {
    isLoading,
    pdfPreview,
    setPdfPreview,
    isPdfVisible,
    setIsPdfVisible,
    loadError,
    setLoadError,
    handleLoad,
    handleLoadStart,
    handleError,
    handleClosePdfPreview,
    iframeRef,
  }
}
