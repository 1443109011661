import { IUnSdg } from 'src/types'

import { List, ListImage } from './product-image.styled'

interface IProps {
  unSdg: IUnSdg[]
  withIndicator?: boolean
}

export const UnSdgList = ({ unSdg, withIndicator }: IProps) => {
  return (
    <List withIndicator={withIndicator}>
      {unSdg.map((item) => (
        <li key={item.id}>
          <ListImage src={item.image_medium || item.image} alt={item.name} />
        </li>
      ))}
    </List>
  )
}
