import { CustomModal } from '.'

interface IProps {
  handleClose: () => void
  videoSrc: string
}

export const VideoModal = ({ handleClose, videoSrc }: IProps) => {
  return (
    <CustomModal
      ariaLabel="modal-video"
      open={!!videoSrc}
      handleClose={handleClose}
      closeOnParentClick
      showCloseButton
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        objectFit: 'contain',
        height: '90%',
        width: '90%',
      }}
    >
      <video
        onClick={(e) => {
          e.stopPropagation()
        }}
        autoPlay
        muted
        controls
        playsInline
        style={{
          maxHeight: '100%',
          maxWidth: '100%',
        }}
      >
        <source src={videoSrc || ''} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </CustomModal>
  )
}
