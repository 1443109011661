import { IImpact } from 'src/types'

import { ImpactCard } from './impact-card.component'
import { List } from './impact-cards-hor.styled'
import { forwardRef } from 'react'
interface IProps {
  items: IImpact[]
  withArrows?: boolean
  primaryColor: string
  walletAddress: string
  showWalletAddress: boolean
  showBcInfo: boolean
  accountImage: string
  isImpactsPage: boolean
}

export const ImpactsCardsHor = forwardRef<HTMLDivElement, IProps>(
  (
    {
      items,
      withArrows,
      primaryColor,
      walletAddress,
      showWalletAddress,
      showBcInfo,
      accountImage,
      isImpactsPage,
    },
    ref,
  ) => {
    return (
      <List ref={ref} withArrows={withArrows}>
        {items.map((item) => (
          <ImpactCard
            id={item.id}
            key={item.id}
            title={item.quantity}
            quantityName={item.quantity_type_name}
            subtitle={item.name}
            image={item.image_medium || item.image}
            unSdg={item.un_sdg}
            scans={item.scans}
            primaryColor={primaryColor}
            walletAddress={walletAddress}
            showWalletAddress={showWalletAddress}
            showBcInfo={showBcInfo}
            accountImage={accountImage}
            isImpactsPage={isImpactsPage}
          />
        ))}
      </List>
    )
  },
)
