import { useQuery } from '@tanstack/react-query'

import { journeyService } from 'src/services'
import { QUERY_KEYS } from 'src/constants'

export const useProductJourney = (productId: string, locale: string, simpleProductId?: string) => {
  return useQuery(
    [QUERY_KEYS.GET_PRODUCT_JOURNEY, productId || simpleProductId, locale],
    () => journeyService.getProductJourney(productId, locale, simpleProductId),
    {
      enabled: !!productId || !!simpleProductId,
    },
  )
}
