export const ROUTES = {
  AUTH: 'auth',

  LANGUAGE: ':lng',
  SIDE_LANGUAGE: ':sideLng',
  PRODUCTS: 'product/:productId',
  PRODUCTS_WITH_SIMPLE_ID: ':simpleProductId',
  PRODUCT: 'product',
  IMPACTS: 'impacts',
  ACCOUNTS: 'account/:accountId',
  ACCOUNT: 'account',
  JOURNEY: 'journey',
  JOURNEY_DETAILED: 'journey/details',
}
