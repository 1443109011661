export const LANGUAGES = [
  'nl',
  'pl',
  'he',
  'fi',
  'ar-sa',
  'de',
  'zh',
  'pt-br',
  'fr',
  'cs',
  'it',
  'ja',
  'be',
  'ro',
  'ua',
]

export const DEFAULT_LANGUAGE = 'en'
