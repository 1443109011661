import { ReactNode } from 'react'

import { Container } from './page-container.styled'

interface IProps {
  children: ReactNode
}

export const PageContainer = ({ children }: IProps) => {
  return (
    <Container>
      {children}
    </Container>
  )
}
