export const COLORS = {
  BLACK: '#000',
  BLACK_OPAQUE: 'rgba(0, 0, 0, 0.6)',
  BLACK_OPAQUE_HOVER: 'rgba(0, 0, 0, 0.5)',
  BLACK_OPAQUE_ACTIVE: 'rgba(0, 0, 0, 0.4)',

  WHITE: '#FFF',
  WHITE_HOVER: 'rgba(0, 0, 0, 0.15)',
  WHITE_ACTIVE: 'rgba(0, 0, 0, 0.1)',

  PRIMARY: '#3FACA8',
  PRIMARY_HOVER: '#348B88',
  PRIMARY_ACTIVE: '#48BAB6',

  GREY_LIGHT: '#BEBEBE',
}

export const SPACES = {
  S: '5px',
  M: '10px',
  L: '15px',
  XL: '20px',
}

export const BREAKPOINTS = ['770px', '440px', '560px']
