import { IAccountImpact } from 'src/types'

import { List } from './account-impacts.styled'
import { forwardRef } from 'react'
import { AccountImpactItem } from './account-impacts-item'
interface IProps {
  items: IAccountImpact[]
  withArrows?: boolean
}

export const AccountImpactsList = forwardRef<HTMLDivElement, IProps>(
  ({ items, withArrows }, ref) => {
    return (
      <List ref={ref} withArrows={withArrows}>
        {items.map((item) => (
          <AccountImpactItem
            key={item.id}
            title={item.quantity}
            quantityName={item.quantity_type_name}
            subtitle={item.name}
            image={item.image_medium || item.image}
            unSdg={item.un_sdg}
            item={item}
          />
        ))}
      </List>
    )
  },
)
