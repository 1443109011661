import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { authServie } from 'src/services'
import { QUERY_KEYS, STORAGE_KEYS, URL_QUERY } from 'src/constants'

export const useAuthentication = () => {
  const queryClient = useQueryClient()
  const [searchParams, setSearchParams] = useSearchParams()

  const localStorageRefreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN)
  const localStorageAccessToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN)

  useEffect(() => {
    const handleTokenValidation = async () => {
      try {
        if (!localStorageRefreshToken || !localStorageAccessToken) return

        const tokenStatus = await authServie.validateToken(
          localStorageRefreshToken,
          localStorageAccessToken,
        )
        const refreshTokenStatus = tokenStatus?.status

        // If the token status is false, remove the tokens and exit
        if (!refreshTokenStatus) {
          localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN)
          localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN)
          queryClient.removeQueries([QUERY_KEYS.GET_AUTH_USER])
          queryClient.removeQueries([QUERY_KEYS.GET_ME])
        }
      } catch (error) {
        console.error('Error validating token:', error)
      }
    }

    handleTokenValidation()
  }, [localStorageRefreshToken, localStorageAccessToken])

  const accessToken = searchParams.get(URL_QUERY.ACCESS_TOKEN)
  const refreshToken = searchParams.get(URL_QUERY.REFRESH_TOKEN)
  useEffect(() => {
    if (!accessToken || !refreshToken) return

    localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, accessToken)
    localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, refreshToken)
    ;(async () => {
      queryClient.removeQueries([QUERY_KEYS.GET_AUTH_USER])
      queryClient.removeQueries([QUERY_KEYS.GET_ME])
    })()

    setSearchParams((prev) => {
      prev.delete(URL_QUERY.ACCESS_TOKEN)
      prev.delete(URL_QUERY.REFRESH_TOKEN)

      return prev
    })
  }, [accessToken, refreshToken])
}
