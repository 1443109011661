import { WalletAddress } from '../wallet-address'
import { AvatarSt, AvatarWrapper, ContainerSt, LocationSt, TitleSt } from './account-info.styled'

export const AccountInfo = ({ accountName, image, location, walletAddress, showWalletAddress }) => {
  return (
    <ContainerSt>
      <AvatarWrapper>
        <AvatarSt src={image} alt={accountName} />
        <div>
          <TitleSt variant="h2">{accountName}</TitleSt>
          <LocationSt variant="body2">{location}</LocationSt>
          {showWalletAddress && (
            <LocationSt>
              <WalletAddress address={walletAddress} />
            </LocationSt>
          )}
        </div>
      </AvatarWrapper>
    </ContainerSt>
  )
}
