import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { DEFAULT_LANGUAGE, LANGUAGES, PATHS } from 'src/constants'
import { useGetAccountSimpleId } from 'src/hooks'

export const AccountSideLngsWrapper = () => {
  const { sideLng, accountId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  // Function to check if the accountId is a valid UUID
  const isUuid = (id: string) => {
    const uuidRegex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
    return uuidRegex.test(id)
  }

  // Function to check if the accountId is a valid 6-character simple ID
  const isSimpleId = (id: string) => {
    return id.length === 6 && /^[a-zA-Z0-9]+$/.test(id)
  }

  const { data, isLoading } = useGetAccountSimpleId(isUuid(accountId) ? accountId : null)

  useEffect(() => {
    if (isUuid(accountId)) {
      if (!isLoading && data) {
        const targetPath =
          sideLng === DEFAULT_LANGUAGE
            ? `/${PATHS.ACCOUNT}/${data}`
            : `/${sideLng}/${PATHS.ACCOUNT}/${data}`
        navigate(targetPath, { replace: true })
      }
    } else if (isSimpleId(accountId)) {
      // Check if `sideLng` is a valid language code
      if (LANGUAGES.includes(sideLng)) {
        return
      }

      if (sideLng === DEFAULT_LANGUAGE && accountId) {
        // Ensure `en` is in the path before trying to replace it
        if (location.pathname.includes(DEFAULT_LANGUAGE)) {
          const newPath = location.pathname.replace('/en', '')
          navigate(newPath, { replace: true })
        }
        return
      }
      window.location.replace('https://trackgood.io/')
    }
  }, [sideLng, accountId, location, navigate, isLoading, data])

  return LANGUAGES.includes(sideLng) ? <Outlet /> : null
}
