import { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useState } from 'react'

interface IDrawer {
  isShowed: boolean
  setIsShowed: Dispatch<SetStateAction<boolean>>
}

export const BlockchainContext = createContext<IDrawer | null>(null)

export const BlockchainProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isShowed, setIsShowed] = useState(false)

  return (
    <BlockchainContext.Provider
      value={{
        isShowed,
        setIsShowed,
      }}
    >
      {children}
    </BlockchainContext.Provider>
  )
}

export const uselBlockchainModal = () => {
  const context = useContext(BlockchainContext)
  if (!context) {
    throw new Error('useDrawerer must be used within a DrawerProvider')
  }
  return context
}
