import { Avatar, Typography, styled } from '@mui/material'

export const DetailsContainer = styled('div')`
  height: 100%;
  width: 100%;
  /* overflow: auto; */
  flex: 1;
`

export const DetailsTitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(1.75)};
`

export const DetailsDescription = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(2.5)};
`

export const MediaListSt = styled('ul')`
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(0.5)};
`

export const MediaImage = styled(Avatar)`
  width: 90px;
  height: 90px;

  border-radius: 6px;
`

export const ProductsListSt = styled('ul')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.25)};

  overflow-x: auto;
`

export const ProductImage = styled(Avatar)`
  width: 140px;
  height: 166px;
  border-radius: 10px;
`
