import { ReactNode } from 'react'
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import { Link } from 'react-router-dom'

interface IProps {
  icon: ReactNode
  text?: string | ReactNode
  divider?: boolean
  padding?: string
  to: string
  state: object
}

export const PopUpLinkItem = ({ icon, text, divider, padding, to, state }: IProps) => {
  return (
    <Link style={{ textDecoration: 'none', color: 'inherit' }} to={to} state={state}>
      <MenuItem
        sx={{ padding: padding ? padding : 2, width: '180px' }}
        aria-label="Home"
        divider={divider}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        {text && <ListItemText>{text}</ListItemText>}
      </MenuItem>
    </Link>
  )
}
