import { IconButton, styled } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

export const LeftButton = styled(IconButton)<{
  absolute?: boolean
  isImpactPage?: boolean
  isTopDivider?: boolean
}>`
  display: flex;
  position: ${(props) => (props.absolute ? 'absolute' : 'static')};
  top: ${(props) => (!props.isTopDivider && props.isImpactPage ? '5px' : '20px')};
  left: ${(props) => (props.isImpactPage ? '20px' : '20px')};
  z-index: 99999;

  padding: ${({ theme }) => theme.spacing(1.25)};
  color: ${({ theme }) => theme.palette.common.white};
`

export const RightButton = styled(IconButton)<{ isImpactPage?: boolean; isTopDivider?: boolean }>`
  display: flex;
  position: absolute;
  top: ${(props) => (!props.isTopDivider && props.isImpactPage ? '5px' : '20px')};
  right: ${(props) => (props.isImpactPage ? '20px' : '20px')};
  z-index: 99999;

  padding: ${({ theme }) => theme.spacing(1.25)};
  color: ${({ theme }) => theme.palette.common.white};
`

export const NextPageIcon = styled(ArrowForwardIosIcon)`
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  padding: ${({ theme }) => theme.spacing(1)};
  z-index: 99999;

  width: 23px;
  height: 23px;
`

export const PrevPageIcon = styled(ArrowBackIosNewIcon)`
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  padding: ${({ theme }) => theme.spacing(1)};
  z-index: 99999;

  width: 23px;
  height: 23px;
`
