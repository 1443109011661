import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material'
import { IMedia, IUnSdg } from 'src/types'
import parse from 'html-react-parser'

import { MediaList } from '../media-list'
import { AvatarImg } from '../step-info/step-company.styled'
import { UnSdgList } from '../impacts-cards-ver/un-sdg-list.component'
import { ImageCarousel } from '../image-carousel'
import { LikesCounter } from '../impacts-cards-ver/likes-counter.component'

interface IProps {
  title: string | number
  subtitle: string
  image: string
  description: string
  media: IMedia[]
  mainMedia: IMedia[]
  unSdg: IUnSdg[]
  likes: number
  impactId: string
  liked: boolean
  quantityName: string
  showUnSdg: boolean
}

export const AccountImpactDetails = (props: IProps) => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        position: 'relative',
        width: '100%',
        maxWidth: '800px',
        margin: '0 auto',
        padding: '5px 32px 20px',
        // height: '100%',

        // borderRadius: '15px',
        background: theme.palette.background.default,
        // boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Box
        sx={{
          padding: theme.spacing(2.5),
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(2.5),
        }}
      >
        <AvatarImg borderRadius={'10px'} width={'100px'} src={props.image} alt="" />
        {/* <ItemImage
          style={{ borderRadius: 6, backgroundImage: `url(${props.image})`, zIndex: 12 }}
        /> */}
        <div>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              columnGap: '5px',
              alignItems: 'baseline',
              pb: theme.spacing(0.5),
            }}
          >
            <Typography sx={{ fontSize: '36px' }} variant="h2">
              {props.title}
            </Typography>
            <Typography sx={{ margin: '10px 5px 10px 0px' }} variant="h4">
              {props.quantityName}
            </Typography>
          </Box>
          <Typography sx={{ margin: '10px 5px 10px 0px' }} variant="subtitle2">
            {props.subtitle}
          </Typography>
        </div>
        {!isTablet && props.showUnSdg && <UnSdgList unSdg={props.unSdg} />}
      </Box>
      {props.mainMedia.length ? (
        <Box sx={{ mb: theme.spacing(1.25) }}>
          <ImageCarousel isStep images={props.mainMedia} />
        </Box>
      ) : null}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1.25), pb: '24px' }}>
        <LikesCounter count={props.likes} userLiked={props.liked} impactId={props.impactId} />
        {isTablet && props.showUnSdg && (
          <Box mx={'12px'}>
            <UnSdgList unSdg={props.unSdg} />
          </Box>
        )}
        {props.description && props.description !== '<p><br></p>' ? (
          <Typography sx={{ margin: '10px 12px' }} variant="body1">
            {parse(props.description)}
          </Typography>
        ) : null}
        {props.media.length >= 1 && <Divider sx={{ borderColor: 'rgba(0,0,0,0.08);' }} />}
        {props.media.length >= 1 ? <MediaList media={props.media} /> : null}
      </Box>
    </Box>
  )
}
