import { CircularProgress } from '@mui/material'

export const Loader = () => (
  <div
    style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // position:'absolute',
      // top: '50%',
      // left: '50%',
      // transform: 'translate(-50%, -50%)',
    }}
  >
    <CircularProgress sx={{}} />
  </div>
)
