import { useQuery } from '@tanstack/react-query'

import { accountService } from 'src/services'
import { QUERY_KEYS } from 'src/constants'

export const useGetAccountSimpleId = (
  accountId?: string,
  productId?: string,
  enable: boolean = true,
) => {
  return useQuery(
    [QUERY_KEYS.GET_ACCOUNT_SIMPLE_ID, accountId || productId],
    () => accountService.getAccountSimpleId(accountId, productId),
    {
      enabled: (!!accountId || !!productId) && enable,
    },
  )
}
