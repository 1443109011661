import { HTMLAttributes, ReactNode } from 'react'

import { LayoutContainerSt } from './layout-container.styled'

interface IProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

export const LayoutContainer = ({ children, ...props }: IProps) => {
  return <LayoutContainerSt {...props}>{children}</LayoutContainerSt>
}
