import { Box, IconButton, Link, Typography, useMediaQuery, useTheme } from '@mui/material'
import { ExternalLinkIcon } from 'src/assets/icons/v2/ExternalLinkIcon'
import { COLORS } from 'src/styles/common'

import { getPolygonscanAddressUrl } from 'src/utils'

interface IProps {
  address: string
  label?: string
}

export const WalletAddress = ({ address, label }: IProps) => {
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const truncateAddress = (addr: string) => `${addr.slice(0, 12)}...${addr.slice(-4)}`

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
      {label && (
        <Typography
          sx={{
            fontSize: '10px',
            fontWeight: 600,
            color: COLORS.GREY_LIGHT,
            lineHeight: '1.5',
          }}
        >
          {label}:
        </Typography>
      )}
      <Link
        href={getPolygonscanAddressUrl(address)}
        target="_blank"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '10px',
          color: COLORS.GREY_LIGHT,
          textDecoration: 'none',
        }}
      >
        {isMobile ? truncateAddress(address) : address}
      </Link>
      <IconButton
        component="a"
        href={getPolygonscanAddressUrl(address)}
        target="_blank"
        sx={{ p: '3px' }}
      >
        <ExternalLinkIcon size={16} color={COLORS.GREY_LIGHT} />
      </IconButton>
    </Box>
  )
}
