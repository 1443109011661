import { styled } from '@mui/material'
import MarkSuccessIcon from '@mui/icons-material/CheckCircleRounded'

export const StepsList = styled('ul')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2.5)};
`

export const ItemSt = styled('li')`
  position: relative;
  /* padding-right: 4px;
  padding-left: ${({ theme }) => theme.spacing(0.5)}; */
`

export const LogWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: -8px;
  display: flex;
  align-items: start;
  background-color: ${({ theme }) => theme.palette.background.paper};
  height: 28px;
  z-index: 10;
`

export const LogCircle = styled('span')<{ color?: string }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ theme, color }) => color ?? theme.palette.secondary.main};
`

export const CardWrapper = styled('div')<{ isLastStep: boolean }>(
  ({ isLastStep, theme }) => `
  margin-left: ${theme.spacing(0.75)};
  padding-left: ${theme.spacing(2)};

  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: ${isLastStep ? 'transparent' : theme.palette.grey.A200};
  position: relative;
`,
)

export const LastStepIcon = styled(MarkSuccessIcon)`
  width: 20px;
  height: 20px;
  margin: -2px;

  fill: ${({ theme }) => theme.palette.primary.main};
`
