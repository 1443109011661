import { Box, Typography } from '@mui/material'
import { ReactNode } from 'react'

interface IModalItemProps {
  label?: string | ReactNode
  value: string | ReactNode
  valueSx?: object
  containerSx?: object
  column?: boolean
}

export const BlockchainModalItem = ({
  label,
  value,
  valueSx,
  containerSx,
  column,
}: IModalItemProps) => {
  return (
    <Box
      sx={{
        pb: '10px',
        display: 'flex',
        flexDirection: column ? 'column' : 'row',
        gap: '5px',
        maxWidth: '100%',
        ...containerSx,
      }}
    >
      {label && <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>{label}:</Typography>}
      <Typography sx={{ fontSize: '16px', ...valueSx }}>{value}</Typography>
    </Box>
  )
}
