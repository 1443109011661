import { css } from '@emotion/css'
import { theme } from 'src/providers/theme-provider/theme.provider'

export const imgSt = css`
  && {
    background-color: #fff;

    border-radius: 20%;
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.4);
    overflow: hidden;
    width: 64px;
    height: 64px;
  }
`

export const wrapper = css`
  width: 100%;
  gap: 10px;

  display: flex;
  align-items: center;
`

export const wrapperMobile = css`
  width: 100%;
  gap: 10px;

  display: flex;
  align-items: center;
`

export const titleWrapper = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 12px;
  padding: 8px 15px 8px 8px;
  transition-duration: 200ms;
  border: 0.1px solid ${theme.palette.background.default};
  border-radius: 5px;
`

export const titleDivider = css`
  display: flex;
  flex-grow: 1;
`
