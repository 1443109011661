import { MutableRefObject } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import { Typography } from '@mui/material'

import { JourneySteps, PageContainer } from 'src/components'
import { useAccount, useProductJourney } from 'src/hooks'
import { Title } from './title.component'
import { DescriptionWrapper } from './journey-detailed.styled'

interface IProps {
  topRef?: MutableRefObject<HTMLSpanElement>
}

export default function JourneyDetailed({ topRef }: IProps) {
  const [, i18n] = useTranslation()
  const { productId, simpleProductId } = useParams()
  const { data } = useProductJourney(productId, i18n.language, simpleProductId)
  const { data: account, isLoading } = useAccount(data.product.account_id, i18n.language)

  const journey = data?.product?.journey
  const steps = journey?.steps || []

  if (isLoading) {
    return null
  }

  return (
    <PageContainer>
      {/* {isTablet && <PreviousPage onClick={handleNavigate} />} */}
      <Title />
      {journey.description && journey.description !== '<p><br></p>' ? (
        <DescriptionWrapper>
          <Typography variant="body1">{parse(journey.description)}</Typography>
        </DescriptionWrapper>
      ) : null}
      <JourneySteps
        primaryColor={account?.primary_color}
        secondaryColor={account?.secondary_color}
        hideAccountName={account?.hide_name}
        detailed
        steps={steps}
        topRef={topRef}
        walletAddress={account.wallet_address}
        showWalletAddress={account.show_wallet_address}
        showBcInfo={account.show_bc_info}
        accountImage={account?.image_small || account?.image_medium}
      />
    </PageContainer>
  )
}
