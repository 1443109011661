import { useQueryClient } from '@tanstack/react-query'
import { authServie } from 'src/services'
import { QUERY_KEYS, STORAGE_KEYS } from 'src/constants'

export const useLogout = () => {
  const queryClient = useQueryClient()

  return async () => {
    const accessToken = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN)
    const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN)

    if (accessToken && refreshToken) {
      try {
        await authServie.deleteTokens(accessToken, refreshToken)
      } catch (error) {
        console.error('Failed to delete token:', error)
      }
    }

    localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN)
    localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN)

    queryClient.removeQueries([QUERY_KEYS.GET_AUTH_USER])
    queryClient.removeQueries([QUERY_KEYS.GET_ME])
  }
}
