import { useQuery } from '@tanstack/react-query'

import { accountService } from 'src/services'
import { QUERY_KEYS } from 'src/constants'

export const useGetAccountRedirectUrl = (accountId: string, locale: string) => {
  return useQuery(
    [QUERY_KEYS.GET_ACCOUNT_REDIRECT_URL, accountId, locale],
    () => accountService.getAccountRedirectUrls(accountId, locale),
    {
      enabled: !!accountId && !!locale,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )
}
