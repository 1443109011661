export function formatDate(date: Date): string {
  const optionsDate: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }
  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'UTC',
  }

  const formattedDate = new Date(date).toLocaleDateString('en-GB', optionsDate)
  const formattedTime = new Date(date).toLocaleTimeString('en-GB', optionsTime)

  return `${formattedDate}, ${formattedTime} UTC`
}
