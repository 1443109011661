import { useEffect } from 'react'

export const useDdRumSetup = () => {
  useEffect(() => {
    if (window.location.protocol === 'https:') {
      window.DD_RUM = window.DD_RUM || {
        q: [],
        onReady: function (c: any) {
          window.DD_RUM.q.push(c)
        },
      }

      const d = document.createElement('script')
      d.async = true
      d.src = 'https://www.datadoghq-browser-agent.com/eu1/v4/datadog-rum.js'
      const n = document.getElementsByTagName('script')[0]
      if (n && n.parentNode) {
        n.parentNode.insertBefore(d, n)
      }

      window.DD_RUM.onReady(function () {
        window.DD_RUM.init({
          clientToken: 'puba91302eb4f897e060733d7671ca297e4',
          applicationId: '31af0deb-fff1-44aa-89ec-32bd1df42a5f',
          site: 'datadoghq.eu',
          service: 'trackgood-journey-app',
          env:
            process.env.REACT_APP_BRANCH_NAME === 'prod'
              ? 'production'
              : process.env.REACT_APP_BRANCH_NAME === 'test'
                ? 'test'
                : 'development',
          sessionSampleRate: 100,
          sessionReplaySampleRate: 20,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: 'mask-user-input',
        })
        window.DD_RUM.startSessionReplayRecording()
      })
    }
  }, [])
}
