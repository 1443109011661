import { useState } from 'react'
import AudioFileOutlinedIcon from '@mui/icons-material/AudioFileOutlined'
import VideoFileOutlinedIcon from '@mui/icons-material/VideoFileOutlined'

import { ImageModal } from '../modal/image-modal'

import {
  audioFormatRegexp,
  audioRegexp,
  pdfFormatRegexp,
  pdfRegexp,
  videoFormatRegexp,
  videoRegexp,
} from 'src/utils'

import { PdfStubIcon, UrlIcon } from 'src/assets/icons'
import { VideoModal } from '../modal/video-modal'
import { PdfModal } from '../modal/pdf-modal'
import { usePdfModal } from 'src/hooks'

interface IProps {
  type: string
  className?: string
  alt: string
  link: string
  modalLink: string
}

export const MediaRender = ({ type, className, alt, link, modalLink }: IProps) => {
  const [fullscreenImage, setFullscreenImage] = useState<string | null>(null)
  const [fullscreenVideo, setFullscreenVideo] = useState<string | null>(null)
  const [failed, setFailed] = useState(false)

  const {
    handleClosePdfPreview,
    handleError,
    handleLoad,
    handleLoadStart,
    iframeRef,
    isPdfVisible,
    loadError,
    pdfPreview,
    isLoading,
    setIsPdfVisible,
    setPdfPreview,
  } = usePdfModal()

  const handleCloseFullscreen = () => {
    setFullscreenImage(null)
  }

  const handleCloseCideoFullscreen = () => {
    setFullscreenVideo(null)
  }

  const handleImageError = () => {
    setFailed(true)
  }

  const handleImageLoad = () => {
    setFailed(false)
  }

  const handleImageClick = (imageSrc: string) => {
    setFullscreenImage(imageSrc)
  }

  const handleVideoClick = (videoSrc: string) => {
    setFullscreenVideo(videoSrc)
  }

  const handlePdfClick = (pdfSrc: string) => {
    setPdfPreview(pdfSrc)
    setIsPdfVisible(true)
  }

  const isPdf = pdfRegexp.test(type) || pdfFormatRegexp.test(link)
  const isAudio = audioRegexp.test(type) || audioFormatRegexp.test(link)
  const isVideo = videoRegexp.test(type) || videoFormatRegexp.test(link)

  if (type === 'url') {
    return (
      <UrlIcon
        className={className}
        handleClick={() => {
          if (modalLink) {
            window.open(modalLink, '_blank')
          }
        }}
      />
    )
  }

  if (isPdf) {
    return (
      <>
        <PdfStubIcon
          className={className}
          handleClick={() => {
            handlePdfClick(modalLink)
          }}
        />
        <PdfModal
          handleClosePdfPreview={handleClosePdfPreview}
          handleError={handleError}
          handleLoad={handleLoad}
          handleLoadStart={handleLoadStart}
          iframeRef={iframeRef}
          isLoading={isLoading}
          isPdfVisible={isPdfVisible}
          loadError={loadError}
          pdfPreview={pdfPreview}
        />
      </>
    )
  }

  if (isAudio) {
    return <AudioFileOutlinedIcon className={className} />
  }

  if (isVideo) {
    return (
      <>
        <VideoFileOutlinedIcon
          onClick={() => {
            handleVideoClick(modalLink)
          }}
          className={className}
        />
        <VideoModal handleClose={handleCloseCideoFullscreen} videoSrc={fullscreenVideo} />
      </>
    )
  }

  if (failed) {
    return (
      <UrlIcon
        className={className}
        handleClick={() => {
          if (modalLink) {
            window.open(modalLink, '_blank')
          }
        }}
      />
    )
  }

  return (
    <>
      <img
        onClick={() => {
          handleImageClick(modalLink)
        }}
        alt={alt}
        src={link}
        onError={handleImageError}
        onLoad={handleImageLoad}
        className={className}
      />
      <ImageModal handleClose={handleCloseFullscreen} imageSrc={fullscreenImage} />
    </>
  )
}
