import { IAccountImpact, IUnSdg } from 'src/types'
import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'

import { useCustomModal } from 'src/hooks'
import { AccountImpactDetails } from './account-impact-details'
import { CustomModalAnimated } from '../modal-animated'

interface IProps {
  title: string | number
  subtitle: string
  image: string
  unSdg: Array<IUnSdg>
  quantityName: string
  item: IAccountImpact
}

export const AccountImpactItem = ({ image, title, subtitle, quantityName, item }: IProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { handleClose, handleOpen, open } = useCustomModal()

  return (
    <Box sx={{ position: 'relative', cursor: 'pointer' }}>
      <Box
        onClick={handleOpen}
        sx={{
          height: 'auto',

          transition: 'transform 200ms, filter 200ms',
          willChange: 'transform, filter',
          '&:hover': {
            filter: ' brightness(90%)',
            transform: 'scale(0.98)',
          },
        }}
      >
        <Box
          sx={{
            width: '120px',
            maxHeight: '200px',
            minHeight: '165px',

            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

            borderRadius: '7px',
            background: theme.palette.background.default,
            boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)',
            overflow: 'hidden',
            textDecoration: 'none',
            color: 'inherit',
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${image})`,
              width: '100%',
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              height: '110px',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              flexGrow: 1,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: title ? 'flex-start' : 'center',
              py: '4px',
              px: '8px',
            }}
          >
            {title && (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  columnGap: '2px',
                  alignItems: 'baseline',
                  pb: '4px',
                }}
              >
                <Typography
                  sx={{ textOverflow: 'ellipsis', textAlign: 'center', wordBreak: 'break-word' }}
                  variant="h4"
                >
                  {title}
                </Typography>
                <Typography
                  sx={{ textOverflow: 'ellipsis', textAlign: 'center', wordBreak: 'break-word' }}
                  variant="h6"
                >
                  {quantityName}
                </Typography>
              </Box>
            )}
            <Typography
              sx={{ textOverflow: 'ellipsis', textAlign: 'center', wordBreak: 'break-word' }}
              variant="body2"
            >
              {subtitle}
            </Typography>
          </Box>
        </Box>
      </Box>

      <CustomModalAnimated
        ariaLable={`modal-account-impact-details-${item.id}`}
        open={open}
        handleClose={handleClose}
      >
        <Box
          sx={{
            zIndex: 101,
            width: '100vw',
            height: '100dvh',
            maxHeight: '100dvh',
            backgroundColor: theme.palette.common.white,

            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              color: theme.palette.common.black,
              alignSelf: 'flex-end',
              m: isMobile ? '0px' : '5px',
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              maxHeight: '100dvh',
              overflowY: 'auto',
            }}
          >
            <AccountImpactDetails
              title={item.quantity}
              quantityName={item.quantity_type_name}
              subtitle={item.name}
              image={item.image_medium || item.image}
              unSdg={item.un_sdg || []}
              media={item.media || []}
              mainMedia={item.mainMedia || []}
              description={item.description}
              likes={item.totalLikes}
              liked={item.liked}
              impactId={item.id}
              showUnSdg={true}
            />
          </Box>
        </Box>
      </CustomModalAnimated>
    </Box>
  )
}
