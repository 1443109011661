import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useParams } from 'react-router-dom'
import moment from 'moment'

import { useAuthentication, useGetAccountRedirectUrl, useInjectCss } from 'src/hooks'
import useGoogleAnalytics from 'src/hooks/common/use-google-analytics.hook'

export const RoutesWrapper = () => {
  const { i18n } = useTranslation()
  const { accountId } = useParams()

  const { data, isLoading } = useGetAccountRedirectUrl(accountId, i18n.language)

  useEffect(() => {
    if (!isLoading && data) {
      if (data.override_url) {
        window.location.replace(data.override_url)
        return
      }
    }
  }, [isLoading, data])

  moment.locale(i18n.language)

  useAuthentication()
  useInjectCss()
  useGoogleAnalytics()

  return <Outlet />
}
