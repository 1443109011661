import { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useState } from 'react'

export enum EType {
  PRODUCT = 'product',
  JOURNEY = 'journey',
  IMPACTS = 'impacts',
  ACCOUNT = 'about',
}
interface IDrawer {
  active: EType
  setActive: Dispatch<SetStateAction<EType>>
}

export const DrawerContext = createContext<IDrawer | null>(null)

export const DrawerProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [active, setActive] = useState<EType>(EType.PRODUCT)

  return (
    <DrawerContext.Provider
      value={{
        active,
        setActive,
      }}
    >
      {children}
    </DrawerContext.Provider>
  )
}

export const useDrawerer = () => {
  const context = useContext(DrawerContext)
  if (!context) {
    throw new Error('useDrawerer must be used within a DrawerProvider')
  }
  return context
}
