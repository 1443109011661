import { Link } from 'react-router-dom'
import { NextPageIcon, RightButton } from './navigation.styled'

type NextPageProps = {
  to: string
  state: object
  isImpactPage?: boolean
  isTopDivider?: boolean
}

export const NextPage = ({ isImpactPage, isTopDivider, to, state }: NextPageProps) => {
  return (
    <Link to={to} state={state}>
      <RightButton
        size="medium"
        aria-label="Next Page"
        isImpactPage={isImpactPage}
        isTopDivider={isTopDivider}
      >
        <NextPageIcon />
      </RightButton>
    </Link>
  )
}
