import { css } from '@emotion/css'
import { SPACES } from 'src/styles/common'

export const Container = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const TitleWrapper = css`
  margin-left: ${SPACES.XL};
`

export const AccountWrapper = css`
  height: fit-content;
  margin-left: ${SPACES.XL};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${SPACES.L};
`

export const AccountLogo = css`
  border-radius: 5px;
  height: 80px;
  width: 80px;
  background-position: center;
  background-size: cover;
`

export const AccountTexts = css`
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const AccountName = css`
  font-size: 32px;
`

export const AccountSocialsWrapper = css`
  margin: ${SPACES.XL} 0px;
  display: flex;
  justify-content: center;
  gap: ${SPACES.M};
`

export const SocialsItem = css`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
`

export const DescrWrapper = css`
  width: 85%;
`

export const ProductsWrapper = css`
  gap: 20px;
  margin: ${SPACES.XL} 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: center;
`

export const ProductItem = css`
  height: 120px;
  width: 120px;
  border-radius: 4px;
  background-position: center;
  background-size: cover;
`

export const TitleText = css`
  margin: ${SPACES.L} 0px;
  font-size: 20px;
  font-weight: 600;
`

export const AddressText = css`
  font-size: 12px;
`

export const mapViewSt = css`
  width: 98%;
  height: 300px;
  min-height: inherit;
  border-radius: ${SPACES.XL};
`
