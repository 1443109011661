import { useQuery } from '@tanstack/react-query'

import { productService } from 'src/services'
import { QUERY_KEYS } from 'src/constants'

export const usePGetProductSimpleId = (productId: string) => {
  return useQuery(
    [QUERY_KEYS.GET_PRODUCT_SIMPLE_ID, productId],
    () => productService.getProductSimpleId(productId),
    {
      enabled: !!productId,
    },
  )
}
