import axios from 'axios'

import { EDefaultLandingPage, IAccountProductsResp, IProduct, IScan } from 'src/types'
import { HttpService } from './http.service'

class ProductService extends HttpService {
  constructor() {
    super(axios)
  }

  getProduct(productId: string, locale: string, guestId?: string, simpleId?: string) {
    return this.get<IProduct>(`product/${simpleId ? simpleId : productId}?log=true`, {
      params: {
        locale,
        guestId,
      },
    })
  }

  getProductSimpleId(productId: string) {
    return this.get<string>(`product/simple-id/${productId}`)
  }

  getProductRedirectUrls(simpleProductId: string, locale: string) {
    return this.get<{
      default_landing_page: EDefaultLandingPage
      override_url: string
      public: boolean
    }>(`product/redirect-urls/${simpleProductId}/${locale}`)
  }

  getProductScans(simpleProductId: string) {
    return this.get<IScan[]>(`product/scans/${simpleProductId}`)
  }

  getAccountProducts(accountId: string, withoutProduct?: boolean) {
    if (withoutProduct) {
      return this.get<IAccountProductsResp>(`account/${accountId}/products?log=true`)
    }
    return this.get<IAccountProductsResp>(`account/${accountId}/products`)
  }
}

export const productService = new ProductService()
