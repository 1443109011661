import { CardContent, styled } from '@mui/material'

export const CardContentSt = styled(CardContent)`
  padding: 0;

  border-radius: 15px;
  box-shadow: 0px 3px 10px 0px ${({ theme }) => theme.palette.grey.A200};
`

export const UpperContentSt = styled('div')`
  padding: 7px 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  /* width: 100%; */
`

export const CarouselWrapper = styled('div')`
  margin-bottom: 10px;
  /* height: 267px; */
`

export const LowerContentSt = styled('div')`
  /* padding: ${({ theme }) => theme.spacing(1.25)} ${({ theme }) => theme.spacing(2.5)}
    ${({ theme }) => theme.spacing(1.75)}; */
`

export const CardChip = styled('div')`
  margin-bottom: 10px;
  margin-right: 6px;
  padding: 7px 15px;

  /* min-width: 120px; */
  /* height: 30px; */

  border-radius: 20px;
  background-color: ${({ theme }) => theme.palette.grey.A200};

  font-size: 14px;
  font-weight: 600;
  line-height: 1.33;
`

export const InfoWrapper = styled('div')`
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};
`

export const StepImpactsList = styled('ul')`
  /* margin-left: 70px; */
  overflow-x: scroll;
  width: 100%;
  margin-top: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.25)};

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`
