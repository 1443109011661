import { IMedia } from 'src/types'
import { useTheme } from '@mui/material'

import { HoverWrapper } from 'src/components'
import { MediaRender } from '../media-render'
import { ItemSt, LinkSt, MediaListSt, TitleSt, mediaSt } from './media-list.styled'

interface IProps {
  media: IMedia[]
  size?: number
}

export const MediaList = ({ media, size }: IProps) => {
  const theme = useTheme()

  return (
    <MediaListSt>
      {media.map((item) => {
        return (
          <HoverWrapper key={item.id} title={item.title}>
            <ItemSt>
              <LinkSt size={size}>
                <MediaRender
                  className={mediaSt(theme)}
                  type={item.type}
                  alt={item.title}
                  link={item.link_small || item.link}
                  modalLink={item.link}
                />
              </LinkSt>
              <TitleSt>{item.title}</TitleSt>
            </ItemSt>
          </HoverWrapper>
        )
      })}
    </MediaListSt>
  )
}
